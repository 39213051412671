// brands
export const REQUEST_GET_CARS_BRANDS = 'REQUEST_GET_CARS_BRANDS'
export const GET_CARS_BRANDS_SUCCESS = 'GET_CARS_BRANDS_SUCCESS'  
export const PUSH_BRANDS_SUCCESS = 'PUSH_BRANDS_SUCCESS'
// models
export const REQUEST_GET_CARS_MODELS = 'REQUEST_GET_CARS_MODELS'
export const GET_CARS_MODELS_SUCCESS = 'GET_CARS_MODELS_SUCCESS'   
// Tariffs
export const REQUEST_GET_TARIFFS = 'REQUEST_GET_TARIFFS'
export const REQUEST_GET_TARIFFS_SUCCESS = 'REQUEST_GET_TARIFFS_SUCCESS'   
// Regions
export const REQUEST_GET_REGIONS_DICT = 'REQUEST_GET_REGIONS_DICT'
export const SUCCESS_GET_REGIONS_DICT = 'SUCCESS_GET_REGIONS_DICT'   
// get all dict
export const REQUEST_GET_ALLDICT_STATIC = 'REQUEST_GET_ALLDICT_STATIC'   
export const SUCCESS_GET_ALLDICT_STATIC = 'SUCCESS_GET_ALLDICT_STATIC'    
// autoClassId
export const REQUEST_GET_AUTOCLASSID_STATIC = 'REQUEST_GET_AUTOCLASSID_STATIC'   
export const SUCCESS_GET_AUTOCLASSID_STATIC = 'SUCCESS_GET_AUTOCLASSID_STATIC'
/*// получае информацию о водителе для инфо карточки
export const REQUEST_GET_DRIVER_INFO = 'REQUEST_GET_DRIVER_INFO'
export const SUCCESS_GET_DRIVER_INFO = 'SUCCESS_GET_DRIVER_INFO'*/
