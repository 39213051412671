// filter
export const REQUEST_CHANGE_PHOTOSINSP_STATUS = 'REQUEST_CHANGE_PHOTOSINSP_STATUS'
export const REQUEST_CHANGE_PHOTOSINSP_SEARCH = 'REQUEST_CHANGE_PHOTOSINSP_SEARCH'
export const CHANGE_RIGHT_FILTER_PHOTOSINSP = 'CHANGE_RIGHT_FILTER_PHOTOSINSP' 
export const USE_FILTER_SUCCESS_PHOTOSINSP = 'USE_FILTER_SUCCESS_PHOTOSINSP' 
// get
export const REQUEST_GET_PHOTOSINSP = 'REQUEST_GET_PHOTOSINSP'
export const GET_DATA_PHOTOSINSP_SUCCESS = 'GET_DATA_PHOTOSINSP_SUCCESS' 
export const GET_DATA_PHOTOSINSP_ERROR = 'GET_DATA_PHOTOSINSP_ERROR' 
// delete 
export const REQUEST_DELETE_PHOTOSINSP = 'REQUEST_DELETE_PHOTOSINSP'
export const DELETE_PHOTOSINSP_SUCCESS = 'DELETE_PHOTOSINSP_SUCCESS' 
export const DELETE_PHOTOSINSP_ERROR = 'DELETE_PHOTOSINSP_ERROR' 
// update 
export const REQUEST_UPDATE_PHOTOSINSP = 'REQUEST_UPDATE_PHOTOSINSP'
export const UPDATE_PHOTOSINSP_SUCCESS = 'UPDATE_PHOTOSINSP_SUCCESS' 
export const UPDATE_PHOTOSINSP_ERROR = 'UPDATE_PHOTOSINSP_ERROR' 
// create 
export const REQUEST_CREATE_PHOTOSINSP = 'REQUEST_CREATE_PHOTOSINSP'
export const CREATE_PHOTOSINSP_SUCCESS = 'CREATE_PHOTOSINSP_SUCCESS' 
export const CREATE_PHOTOSINSP_ERROR = 'CREATE_PHOTOSINSP_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_PHOTOSINSP = 'SHOW_WINDOW_ADD_PHOTOSINSP' 
export const SHOW_WINDOW_UPDATE_PHOTOSINSP = 'SHOW_WINDOW_UPDATE_PHOTOSINSP'  
// get cars in window
export const REQUEST_GET_CARS_FOR_PHOTOSINSP = 'REQUEST_GET_CARS_FOR_PHOTOSINSP' 
export const GET_CARS_FOR_PHOTOSINSP_SUCCESS = 'GET_CARS_FOR_PHOTOSINSP_SUCCESS'  
// очищение реквестов
export const CLEAR_CREATE_FIELDS_PHOTOSINSP = 'CLEAR_CREATE_FIELDS_PHOTOSINSP' 
export const CLEAR_UPDATE_FIELDS_PHOTOSINSP = 'CLEAR_UPDATE_FIELDS_PHOTOSINSP'  
export const CLEAR_DELETE_FIELDS_PHOTOSINSP = 'CLEAR_DELETE_FIELDS_PHOTOSINSP'  

// rules
	// get
export const REQUEST_GET_RULES_PHOTOSINSP = 'REQUEST_GET_RULES_PHOTOSINSP' 
export const GET_RULES_PHOTOSINSP_SUCCESS = 'GET_RULES_PHOTOSINSP_SUCCESS'   
	// change rules
export const REQUEST_CHANGE_RULES_PHOTOSINSP = 'REQUEST_CHANGE_RULES_PHOTOSINSP' 
export const CHANGE_RULES_PHOTOSINSP_SUCCESS = 'CHANGE_RULES_PHOTOSINSP_SUCCESS' 
export const CHANGE_RULES_PHOTOSINSP_ERROR = 'CHANGE_RULES_PHOTOSINSP_ERROR'  
export const CLEAR_CHANGE_RULES_FIELDS_PHOTOSINSP = 'CLEAR_CHANGE_RULES_FIELDS_PHOTOSINSP'  
	// get photos for one
export const REQUEST_GET_DRIVERPHOTOS_PHOTOSINSP = 'REQUEST_GET_DRIVERPHOTOS_PHOTOSINSP' 
export const GET_DRIVERPHOTOS_PHOTOSINSP_SUCCESS = 'GET_DRIVERPHOTOS_PHOTOSINSP_SUCCESS'    
	// agreeDisagreeWidthPhotos
export const REQUEST_AGREE_DISAGREE_PHOTOS_PHOTOSINSP = 'REQUEST_AGREE_DISAGREE_PHOTOS_PHOTOSINSP'    
export const SUCCESS_AGREE_DISAGREE_PHOTOS_PHOTOSINSP = 'SUCCESS_AGREE_DISAGREE_PHOTOS_PHOTOSINSP'      
export const ERROR_AGREE_DISAGREE_PHOTOS_PHOTOSINSP = 'ERROR_AGREE_DISAGREE_PHOTOS_PHOTOSINSP'      
export const CLEAR_GREE_DISAGREE_PHOTOS_FIELDS_PHOTOSINSP = 'CLEAR_GREE_DISAGREE_PHOTOS_FIELDS_PHOTOSINSP'

//карточка водителя при наведении
export const REQUEST_GET_DRIVER_INFO = 'REQUEST_GET_DRIVER_INFO'
export const SUCCESS_GET_DRIVER_INFO = 'SUCCESS_GET_DRIVER_INFO'
