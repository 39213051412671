import axios from 'axios'
 
export default {  
	getData: (filter) => axios.post(`/creatingCompany/list`, filter).then(r => r.data),
	createData: (data) => axios.post(`/creatingCompany/add`, data).then(r => r.data),
	updateData: (data) => axios.post(`/creatingCompany/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (data) => axios.post(`/creatingCompany/del/${data.id}`).then(r => r.data), 

	getServices: (data) => axios.post(`/creatingCompany/services`).then(r => r.data), 

	getOneCompany: (data) => axios.post(`/creatingCompany/get/${data.id}`).then(r => r.data), 
	changeCompanyOrders: (data) => axios.post(`/creatingCompany/changeCompanyOrders`, data).then(r => r.data), 
	sendCorrectData: (data) => axios.post(`/creatingCompany/changeBalance/${data.id}`, data.payload).then(r=>r.data),
	sendCompanyId: (data) => axios.post(`/creatingCompany/subCompanies/${data.id}`, data).then(r=>r.data)
}
  