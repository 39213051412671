import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters'; 
import dict from '../../../../core/dict/controlRoom/blacklist'; 
 

const initialState = { 
	filter: {
		status: dict.status[0].value,  
		paramSearch: dict.params[0].value,  
		valueSearch: '',  
		rightFilter: { 
		 
		}, 
	}, 
	data: [],
	subCompanies: [],
	correct:{
		loading: false,  
		error: false,   
		success: false,
	},  
	loading: false,  
	create: { // для работы при создании 
		loading: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loading: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loading: false,  
		error: false,   
		success: false,   
	},   
	audit: {
		data: [], 
		loading: true
	}, // журнал 
	services: {
		data: [], 
		loading: true
	},  
	oneCompany: {
		data: {},
		loading: true,
	}, 
	changeCompanyOrders: {
		success: false,
		error: false,
	}, 
}

export default (state=initialState, action) => {
	  
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_CREATECOMP_STATUS:   
			return {...state, loading: true, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_CREATECOMP_SEARCH: 
			return {...state, loading: true, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}}

		case types.CHANGE_RIGHT_FILTER_CREATECOMP:  
			return {...state, loading: true, filter: {...state.filter, rightFilter: action.payload}}

		case types.USE_FILTER_SUCCESS:    
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.CHANGE_ANY_FILTER_CREATECOMP:   // non async  
			return {...state,  filter: {...state.filter, ...action.payload}} 

	// send correct data

		case types.SEND_CORRECT_DATA:
			return {...state, correct: { ...state.correct, loading: true}}
		case types.SEND_CORRECT_DATA_SUCCESS:
			newData = state.data.map(el=>{
				// console.log('reducer action', action)
				if(el.id === action.payload.id) el = action.payload;
				return el;
			})
			return{...state, correct: {...state.correct, loading: false, success: true, error: false}, data: newData}
		case types.SEND_CORRECT_DATA_ERROR:
			return {...state, correct:{...state.correct, loading: false, error: action.error, success: false}}
		case types.CLEAR_CORRECT_DATA_FIELDS:
			return {...state, correct:{...state.correct, loading: false, error: false, success: false}}


		// --- Async get ->>
		case types.REQUEST_GET_CREATECOMP: 
			return {...state, loading: true}

		case types.GET_DATA_CREATECOMP_SUCCESS:   
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.GET_DATA_CREATECOMP_ERROR: 
			return {...state, loading: false, data: [], error: action.payload} 

		// get subcompanies
		case types.SEND_COMPANY_ID:
			return {...state, loading: true}
		case types.SEND_COMPANY_ID_SUCCESS:
				return {...state, loading: false, subCompanies: filterDataForId(action.payload)}
		case types.SEND_COMPANY_ID_ERROR:
				return {...state, loading: false, subCompanies: [], error: action.payload}


		// --- Async create ->>
		case types.REQUEST_CREATE_CREATECOMP: 
			return {...state, create: {...state.create, loading: true}}  
		case types.CREATE_CREATECOMP_SUCCESS: 
			let newData = state.data.concat(action.payload) 
			return {...state, create: {...state.create, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_CREATECOMP_ERROR: 
			return {...state, create: {...state.create, loading: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS_CREATECOMP: 
			return {...state, create: {...state.create, loading: false, error: false, success: false}} // initial  

		// --- Async update data ->>
	case types.REQUEST_UPDATE_CREATECOMP: 
		return {...state, update: {...state.update, loading: true}}  
	case types.UPDATE_CREATECOMP_SUCCESS: 
			newData = state.data.map(el => { 
			if(el.id === action.payload.id) el = action.payload
			return el
		})
		return {...state, update: {...state.update, loading: false, success: true, error: false}, data: newData}  
	case types.UPDATE_CREATECOMP_ERROR: 
		return {...state, update: {...state.update, loading: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_CREATECOMP: 
		return {...state, update: {...state.update, loading: false, error: false, success: false}} // initial 
 
 

		// --- Async delete data  Отмена заказа->>
		case types.REQUEST_DELETE_CREATECOMP:  
			return {...state, delete: {...state.delete, loading: true}}  
		case types.DELETE_CREATECOMP_SUCCESS:  
			// если статус отменные (6) = как и наш фильтр статус то ничего не делаем - иначе удаляем   
			newData = state.data.filter(el=> el.id !== action.payload)  
			return {...state, delete: {...state.delete, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.DELETE_CREATECOMP_ERROR: 
			return {...state, delete: {...state.delete, loading: false, error: action.error, success: false}}  
		case types.CLEAR_DELETE_FIELDS_CREATECOMP: 
			return {...state, delete: {...state.delete, loading: false, error: false, success: false}} // initial 


		// показ окон
		case types.SHOW_WINDOW_ADD_CREATECOMP:    
			return {...state, windowCreateOpened: action.payload} 
		case types.SHOW_WINDOW_UPDATE_CREATECOMP:    
			return {...state, windowUpdateOpened: action.payload} 
  
 
		// отправлять/получать заказы чекбоксами      
		case types.REQUEST_CHANGE_COMPANYORDERS_CREATECOMP:  
			let payload = action.payload.payload  
			// console.log(payload);
			newData = state.data.map(el => {
				if(String(el.id) === String(payload.id)) el[payload.name] = payload.checked
				return el
			})   
			return {...state, changeCompanyOrders: {error: false, success: true}, data: filterDataForId(newData)}    
		case types.SUCCESS_CHANGE_COMPANYORDERS_CREATECOMP:      
			return {...state, changeCompanyOrders: {error: false, success: true }}
		case types.ERROR_CHANGE_COMPANYORDERS_CREATECOMP:    
			payload = action.error.payload 
			newData = state.data.map(el => { 
				if(String(el.id) === String(payload.id)) el[payload.name] = false 
				return el
			})   
			return {...state, changeCompanyOrders: {error: true, success: false}, data: filterDataForId(newData)}  
		case types.CLEAR_FIELDS_CHANGE_COMPANYORDERS_CREATECOMP: 
			return {...state, changeCompanyOrders: {error: false, success: false}}   
		 
		// --- WINDOWS request ->>   
		// показ окон для создание апдейта пассажиров 
		case types.SHOW_WINDOW_PASSENGERS_ADD_CREATECOMP:  
			return {...state, passengersFields: {...state.passengersFields, modalCreateOpened: action.payload}}  
		case types.SHOW_WINDOW_PASSENGERS_UPDATE_CREATECOMP:  
			return {...state, passengersFields: {...state.passengersFields, modalUpdateOpened: action.payload}}  

		// получаем журнал
		case types.REQUEST_GET_AUDIT_FOR_CREATECOMP:  
			return {...state, audit: {...state.audit, loading: true}}  
		case types.SUCCESS_GET_AUDIT_FOR_CREATECOMP:  
			return {...state, audit: {...state.audit, loading: false, data: action.payload}}  

		// получаем сервисы для таба
		case types.REQUEST_GET_SERVICES_FOR_CREATECOMP:  
			return {...state, services: {...state.services, loading: true}}  
		case types.SUCCESS_GET_SERVICES_FOR_CREATECOMP:  
			return {...state, services: {...state.services, loading: false, data: action.payload}} 

		// получаем сервисы для таба
		case types.REQUEST_GET_COMPANY_FOR_CREATECOMP:  
			return {...state, oneCompany: {...state.oneCompany, loading: true}}  
		case types.SUCCESS_GET_COMPANY_FOR_CREATECOMP:  
			if(Object.keys(action.payload).length) newData = action.payload
			else  newData = state.oneCompany
			return {...state, oneCompany: {...state.oneCompany, loading: false, data: newData}}  
 
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
			 
		default: 
			return state
	}
}
 