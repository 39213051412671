import * as types from './types';
	

export const requestLogin = (payload) => ({type: types.REQUEST_LOGIN, payload})
export const successLogin = (payload) => ({type: types.SUCCESS_LOGIN, payload})
export const errorLogin = (payload) => ({type: types.ERROR_LOGIN, payload}) 

export const receiveUserFromToken = (payload) => ({type: types.RECEIVE_USER_IN_TOKEN, payload}) 
export const delUserInToken = (payload) => ({type: types.DEL_USER_IN_TOKEN, payload}) 

export const requestGetCompanyBalance = (payload) => ({type: types.REQUEST_GET_COMPANYBALANCE_LOGIN, payload}) 
export const successGetCompanyBalance = (payload) => ({type: types.SUCCESS_GET_COMPANYBALANCE_LOGIN, payload}) 