import * as types from './types';
 
	
// get  brands models
export const requestGetBrands = (payload) => ({type: types.REQUEST_GET_CARS_BRANDS, payload})
export const requestGetModels = (payload) => ({type: types.REQUEST_GET_CARS_MODELS, payload})
export const getBrandsSucces = (payload) => ({type: types.GET_CARS_BRANDS_SUCCESS, payload})
export const getModelsSucces = (payload) => ({type: types.GET_CARS_MODELS_SUCCESS, payload})
export const pushBrandsSuccess = payload => ({type: types.PUSH_BRANDS_SUCCESS, payload}) 
// regions
export const requestGetRegions= (payload) => ({type: types.REQUEST_GET_REGIONS_DICT, payload})
export const successGetRegions= (payload) => ({type: types.SUCCESS_GET_REGIONS_DICT, payload})
	
// get Tariffs
export const requestGetTariffs = (payload) => ({type: types.REQUEST_GET_TARIFFS, payload})
export const getTariffsSuccess = (payload) => ({type: types.REQUEST_GET_TARIFFS_SUCCESS, payload}) 
	
// get autoClassId
export const requestGetAutoClassId = (payload) => ({type: types.REQUEST_GET_AUTOCLASSID_STATIC, payload})
export const getAutoClassIdSuccess = (payload) => ({type: types.SUCCESS_GET_AUTOCLASSID_STATIC, payload})  

// get all dict
export const requestGetAllDict = (payload) => ({type: types.REQUEST_GET_ALLDICT_STATIC, payload})
export const getAllDictSuccess = (payload) => ({type: types.SUCCESS_GET_ALLDICT_STATIC, payload})

/*export const requestGetDriverInfo = (payload) => ({type: types.REQUEST_GET_DRIVER_INFO, payload})
export const getDriverInfoSuccess = (payload) => ({type: types.SUCCESS_GET_DRIVER_INFO, payload})*/
