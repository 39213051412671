import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_CREATECOMP_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_CREATECOMP_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_CREATECOMP, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS, payload})
//send correct data
export const sendCorrectData = (payload) => ({type: types.SEND_CORRECT_DATA, payload});
export const sendCorrectDataSuccess = (payload) => ({type: types.SEND_CORRECT_DATA_SUCCESS, payload});
export const sendCorrectDataError = (payload) => ({type: types.SEND_CORRECT_DATA_ERROR, payload});
//send single company id
export const sendCompanyId = (payload) => ({type: types.SEND_COMPANY_ID, payload});
export const sendCompanyIdSuccess = (payload) => ({type: types.SEND_COMPANY_ID_SUCCESS, payload});
export const sendCompanyIdError = (payload) => ({type: types.SEND_COMPANY_ID_ERROR, payload});  
//get subcompanies
export const requestGetSubcompanies = (payload) => ({type: types.REQUEST_GET_SUBCOMPANIES, payload})
export const getSubcompaniesSuccess = (payload) => ({type: types.GET_SUBCOMPANIES_DATA_SUCCESS, payload: payload})
export const getSubcompaniesError = (payload) => ({type: types.GET_SUBCOMPANIES_DATA_ERROR, error: payload})
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_CREATECOMP, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_CREATECOMP_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_CREATECOMP_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_CREATECOMP, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_CREATECOMP_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_CREATECOMP_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_CREATECOMP, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_CREATECOMP_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_CREATECOMP_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_CREATECOMP, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_CREATECOMP_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_CREATECOMP_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_CREATECOMP, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_CREATECOMP, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_CREATECOMP}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_CREATECOMP}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_CREATECOMP})
export const clearCorrectDataFields = () => ({type: types.CLEAR_CORRECT_DATA_FIELDS});   

// отправлять/получать заказы чекбоксами
export const requestChangeCompanyOrders = (payload) => ({type: types.REQUEST_CHANGE_COMPANYORDERS_CREATECOMP, payload})
export const successChangeCompanyOrders = (payload) => ({type: types.SUCCESS_CHANGE_COMPANYORDERS_CREATECOMP, payload})
export const errorChangeCompanyOrders = (payload) => ({type: types.ERROR_CHANGE_COMPANYORDERS_CREATECOMP, error: payload})    
export const clearChangeCompanyOrders = (payload) => ({type: types.CLEAR_FIELDS_CHANGE_COMPANYORDERS_CREATECOMP, error: payload})    


// --- Windows request   
//  получаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_CREATECOMP, payload})  
export const getGetAuditSuccess = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_CREATECOMP, payload}) 
//  получаем сервисы для таба
export const requestGetServices = (payload) => ({type: types.REQUEST_GET_SERVICES_FOR_CREATECOMP, payload})  
export const getServicesSuccess = (payload) => ({type: types.SUCCESS_GET_SERVICES_FOR_CREATECOMP, payload})  
//  получаем всю информацию об 1 компании
export const requestGetOneCompany = (payload) => ({type: types.REQUEST_GET_COMPANY_FOR_CREATECOMP, payload})  
export const getOneCompanySuccess = (payload) => ({type: types.SUCCESS_GET_COMPANY_FOR_CREATECOMP, payload}) 
 