// filter
export const REQUEST_CHANGE_TARIFFEDIT_STATUS = 'REQUEST_CHANGE_TARIFFEDIT_STATUS'
export const REQUEST_CHANGE_TARIFFEDIT_SEARCH = 'REQUEST_CHANGE_TARIFFEDIT_SEARCH'
export const CHANGE_RIGHT_FILTER_TARIFFEDIT = 'CHANGE_RIGHT_FILTER_TARIFFEDIT' 
export const USE_FILTER_SUCCESS = 'USE_FILTER_SUCCESS' 
export const CHANGE_ANY_FILTER_TARIFFEDIT = 'CHANGE_ANY_FILTER_TARIFFEDIT' // изменяем любой другой доп фильтр (не сайд-эффект)
// get
export const REQUEST_GET_TARIFFEDIT = 'REQUEST_GET_TARIFFEDIT'
export const GET_DATA_TARIFFEDIT_SUCCESS = 'GET_DATA_TARIFFEDIT_SUCCESS' 
export const GET_DATA_TARIFFEDIT_ERROR = 'GET_DATA_TARIFFEDIT_ERROR' 
// delete 
export const REQUEST_DELETE_TARIFFEDIT = 'REQUEST_DELETE_TARIFFEDIT'
export const DELETE_TARIFFEDIT_SUCCESS = 'DELETE_TARIFFEDIT_SUCCESS' 
export const DELETE_TARIFFEDIT_ERROR = 'DELETE_TARIFFEDIT_ERROR' 
// update 
export const REQUEST_UPDATE_TARIFFEDIT = 'REQUEST_UPDATE_TARIFFEDIT'
export const UPDATE_TARIFFEDIT_SUCCESS = 'UPDATE_TARIFFEDIT_SUCCESS' 
export const UPDATE_TARIFFEDIT_ERROR = 'UPDATE_TARIFFEDIT_ERROR' 
// create 
export const REQUEST_CREATE_TARIFFEDIT = 'REQUEST_CREATE_TARIFFEDIT'
export const CREATE_TARIFFEDIT_SUCCESS = 'CREATE_TARIFFEDIT_SUCCESS' 
export const CREATE_TARIFFEDIT_ERROR = 'CREATE_TARIFFEDIT_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_TARIFFEDIT = 'SHOW_WINDOW_ADD_TARIFFEDIT' 
export const SHOW_WINDOW_UPDATE_TARIFFEDIT = 'SHOW_WINDOW_UPDATE_TARIFFEDIT'  
// get cars in window
export const REQUEST_GET_CARS_FOR_TARIFFEDIT = 'REQUEST_GET_CARS_FOR_TARIFFEDIT' 
export const GET_CARS_FOR_TARIFFEDIT_SUCCESS = 'GET_CARS_FOR_TARIFFEDIT_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_TARIFFEDIT = 'REQUEST_GET_ONECAR_FOR_TARIFFEDIT' 
export const GET_ONECAR_FOR_TARIFFEDIT_SUCCESS = 'GET_ONECAR_FOR_TARIFFEDIT_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_TARIFFEDIT = 'CLEAR_CREATE_FIELDS_TARIFFEDIT' 
export const CLEAR_UPDATE_FIELDS_TARIFFEDIT = 'CLEAR_UPDATE_FIELDS_TARIFFEDIT'  
export const CLEAR_DELETE_FIELDS_TARIFFEDIT = 'CLEAR_DELETE_FIELDS_TARIFFEDIT'  
//  получаем одного
export const REQUEST_GET_ONE_TARIFFEDIT = 'REQUEST_GET_ONE_TARIFFEDIT'  
export const SUCCESS_GET_ONE_TARIFFEDIT = 'SUCCESS_GET_ONE_TARIFFEDIT'    

// --- Windows request 
//  получаем области доставки
export const REQUEST_GET_AREAPARAMS_TARIFFEDIT = 'REQUEST_GET_AREAPARAMS_TARIFFEDIT'   
export const SUCCESS_GET_AREAPARAMS_TARIFFEDIT = 'SUCCESS_GET_AREAPARAMS_TARIFFEDIT'    