import {createStore, applyMiddleware, combineReducers} from 'redux'; 
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from 'redux-saga'; 
// sagas
import rootReducer from './rootReducer';
import rootSaga  from './rootSaga'; 
const sagaMiddleware = createSagaMiddleware(); 
const store = createStore(combineReducers(rootReducer), composeWithDevTools(applyMiddleware(
	sagaMiddleware
)))

sagaMiddleware.run(rootSaga); 
export default store