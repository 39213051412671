import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters'; 
import dict from '../../../../core/dict/controlRoom/clients'; 
 
const initialState = { 
	filter: { 
		paramSearch: dict.search[0].value, // поиск по колонке  
		valueSearch: '', // поиск по колонке - текстовое поле 
		rightFilter: {  
		}, 
	}, 
	data: [],  // список машин
	loading: false, // при загрузки фильтров 
	audit: {
		data: [], 
		loading: true
	}, // журнал 
}

export default (state=initialState, action) => {
	  
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_CLIENTS_STATUS:   
			return {...state, loading: true, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_CLIENTS_SEARCH: 
			return {...state, loading: true, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}}

		case types.CHANGE_RIGHT_FILTER_CLIENTS:  
			return {...state, loading: true, filter: {...state.filter, rightFilter: action.payload}}

		case types.USE_FILTER_SUCCESS_CLIENTS:    
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.CHANGE_ANY_FILTER_CLIENTS:   // non async  
			return {...state,  filter: {...state.filter, ...action.payload}} 
 

		// --- Async get ->>
		case types.REQUEST_GET_CLIENTS: 
			return {...state, loading: true}

		case types.GET_DATA_CLIENTS_SUCCESS:   
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.GET_DATA_CLIENTS_ERROR: 
			return {...state, loading: false, data: [], error: action.payload} 

 
		// показ окон
		case types.SHOW_WINDOW_ADD_CLIENTS:    
			return {...state, windowCreateOpened: action.payload} 
		case types.SHOW_WINDOW_UPDATE_CLIENTS:    
			return {...state, windowUpdateOpened: action.payload} 
  

		// получаем журнал
		case types.REQUEST_GET_AUDIT_FOR_CLIENTS:  
			return {...state, audit: {...state.audit, loading: true}}  
		case types.SUCCESS_GET_AUDIT_FOR_CLIENTS:  
			return {...state, audit: {...state.audit, loading: false, data: action.payload}}  
 
	
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
			
		default: 
			return state
	}
}
 