import axios from 'axios'
//  в статике храним все что может понадобится для нескольких страниц (такого мало)
export default {  
	getCarsModels: () => axios.get(`/dic/car/model`).then(r => r.data),
	getCarsBrands: () => axios.get(`/dic/car/brand`).then(r => r.data), 
	getTariffs: (data) => axios.post(`/tariff/list`, data).then(r => r.data),
	getRegions: () => axios.post(`/dic/regions`).then(r => r.data),
	getAutoClassId: () => axios.post(`/dic/car/class`).then(r => r.data),
	
	getAllDict: () => axios.post(`/dic/all`).then(r => r.data),
	/*getDriverInfo: (id) => axios.post(`driver/getCore/${id}`).then(r => r.data)*/
}
  