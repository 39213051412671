import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters';

const initialState = {
    filter: {
        status: "0",
        // status: "",
        paramSearch: 'shortAddress', // поиск по колонке
        valueSearch: '', // поиск по колонке - текстовое поле
        startDate: new Date(),
        endDate: new Date(),
        payment: 'any',
        rightFilter: {
            autoClassId: '',
            from: '2',
            region: '',
            tariff: '',
        },
        page: 1,
        countPage: 100,
    },
    data: [],
    dataCompanies: [],
    allEntries: null,
    companies: {
        loading: false,
        error: false,
        success: false,
    },

    loading: false, // при загрузки фильтров
    error: false,
    success: false,

    create: { // для работы при создании
        loading: false,
        error: false,
        success: false,
    },
    update: { // для работы при изменении
        loading: false,
        error: false,
        success: false,
    },
    delete: { // при удалении
        loading: false,
        error: false,
        success: false,
    },
    oneDriver: {},
    drivers: {
        data: [],
        loading: false
    }, // предпочитаемый борт
    counterpartys: {
        data: [],
        loading: false
    }, // контрагенты
    persons: {
        data: [],
        loading: false
    }, // персоны контрагента
    passenger: '', // имя пассажира
    from: {
        data: [],
        loading: false
    }, // откуда едет
    to: {
        data: [],
        activeInput: '',
        loading: false
    }, // куда едет
    sum: '', // пред сумма
    tarifList: '', // тарифы
    audit: {
        data: [],
        loading: true
    }, // журнал
    mapTrack: {}, // трэк для карты 1го заказа {data: [], points: []}
    mapTrackLoading: [], // трэк для карты 1го заказа
    taximeter: { // таксометр для вкладки
        data: {},
        loading: true,
    },
    oneOrder: {
        data: {},
        loading: false
    },
    trackedDriverCoord: null
}

const ordersReducer = (state = initialState, action) => {

    switch (action.type) {
        // --- filter
        // меняем статус только ЛОКАЛЬНО
        case types.REQUEST_CHANGE_ORDERS_STATUS:
            return {...state, filter: {...state.filter, status: action.payload, page: 1}}

        case types.REQUEST_CHANGE_ORDERS_SEARCH:
            return {
                ...state,
                loading: true,
                filter: {
                    ...state.filter,
                    paramSearch: action.payload.paramSearch,
                    valueSearch: action.payload.valueSearch,
                    page: 1
                }
            }

        case types.CHANGE_RIGHT_FILTER_ORDERS:
            return {...state, loading: true, filter: {...state.filter, rightFilter: action.payload, page: 1}}

        case types.USE_FILTER_SUCCESS_ORDERS:
            return {
                ...state,
                loading: false,
                data: filterDataForId(action.payload),
                allEntries: action.payload.totalSize
            }

        case types.CHANGE_ANY_FILTER_ORDERS:   // non async
            return {...state, filter: {...state.filter, ...action.payload, page: 1}}

        // меняем  ТОЛЬКО ПАГИНАЦИЮ
        case types.CHANGE_PAGIN_ORDERS:
            return {...state, filter: {...state.filter, ...action.payload}}




        // --- Async get ->>
        case types.REQUEST_GET_ORDERS:
            return {...state, loading: true}

        case types.GET_DATA_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: filterDataForId(action.payload),
                allEntries: action.payload.totalSize
            }

        case types.GET_DATA_ORDERS_ERROR:
            return {...state, loading: false, data: [], error: action.payload}

        // send order
        case types.SEND_ORDER:
            return {...state, loading: true}
        case types.SEND_ORDER_SUCCESS:
            let oData = state.data.map(el => {
                if (el.externalId === action.payload.externalId) el = action.payload;
                return el;
            });
            return {...state, loading: false, data: oData}
        case types.SEND_ORDER_ERROR:
            return {...state, loading: false, error: action.payload}

        case types.REQUEST_GET_DATA_COMPANIES:
            return {...state, loading: true}
        case types.GET_DATA_COMPANIES_SUCCESS:
            return {...state, loading: false, dataCompanies: filterDataForId(action.payload)}
        case types.GET_DATA_COMPANIES_ERROR:
            return {...state, loading: false, dataCompanies: [], error: action.payload}


        // --- Async create ->>
        case types.REQUEST_CREATE_ORDERS:
            return {...state, create: {...state.create, loading: true}}
        case types.CREATE_ORDERS_SUCCESS:
            let newData = state.data.concat(action.payload)
            return {
                ...state,
                create: {...state.create, loading: false, success: true, error: false},
                data: filterDataForId(newData)
            }
        case types.CREATE_ORDERS_ERROR:
            return {...state, create: {...state.create, loading: false, error: action.error, success: false}}
        case types.CLEAR_CREATE_FIELDS_ORDERS:
            return {...state, create: {...state.create, loading: false, error: false, success: false}} // initial

        // --- Async update data ->>
        case types.REQUEST_UPDATE_ORDERS:
            return {...state, update: {...state.update, loading: true}}
        case types.UPDATE_ORDERS_SUCCESS:
            newData = state.data.map(el => {
                if (el.id === action.payload.id) el = action.payload
                return el
            })
            return {...state, update: {...state.update, loading: false, success: true, error: false}, data: newData}
        case types.UPDATE_ORDERS_ERROR:
            return {...state, update: {...state.update, loading: false, error: action.error, success: false}}
        case types.CLEAR_UPDATE_FIELDS_ORDERS:
            return {...state, update: {...state.update, loading: false, error: false, success: false}} // initial

        // Send Companies Data
        case types.SEND_COMPANIES_DATA:
            return {...state, companies: {...state.companies, loading: true}}
        case types.SEND_COMPANIES_DATA_SUCCESS:
            newData = state.data.map(el => {
                if (el.externalId === action.payload.externalId) el = action.payload;
                return el;
            })
            return {
                ...state,
                companies: {...state.companies, loading: false, success: true, error: false},
                data: newData
            }
        case types.SEND_COMPANIES_DATA_ERROR:
            return {...state, companies: {...state.companies, loading: false, error: action.error, success: false}}
        case types.CLEAR_COMPANIES_DATA_FIELDS:
            return {...state, companies: {...state.companies, loading: false, error: false, success: false}}



        // --- Async delete data  Отмена заказа->>
        case types.REQUEST_DELETE_ORDERS:
            return {...state, delete: {...state.delete, loading: true}}
        case types.DELETE_ORDERS_SUCCESS:
            newData = state.data.map(el => {
                if (el && Number(el.externalId) === Number(action.payload.externalId)) el = action.payload
                return el
            })
            return {
                ...state,
                delete: {...state.delete, loading: false, success: true, error: false},
                data: filterDataForId(newData)
            }
        case types.DELETE_ORDERS_ERROR:
            return {...state, delete: {...state.delete, loading: false, error: action.error, success: false}}
        case types.CLEAR_DELETE_FIELDS_ORDERS:
            return {...state, delete: {...state.delete, loading: false, error: false, success: false}} // initial


        // показ окон
        case types.SHOW_WINDOW_ADD_ORDERS:
            return {...state, windowCreateOpened: action.payload}
        case types.SHOW_WINDOW_UPDATE_ORDERS:
            return {...state, windowUpdateOpened: action.payload}


        // --- Async получаем 1 водилу ->>
        case types.REQUEST_GET_DRIVER_FOR_ORDERS:
            return {...state, oneDriver: {}}
        case types.SUCCESS_GET_DRIVER_FOR_ORDERS:
            return {...state, oneDriver: action.payload}


        // --- WINDOWS request ->>
        // получаем контрагента
        case types.REQUEST_GET_COUNTERPARTY_FOR_ORDERS:
            return {...state, counterpartys: {...state.counterpartys, loading: true}}
        case types.SUCCESS_GET_COUNTERPARTY_FOR_ORDERS:
            return {...state, counterpartys: {...state.counterpartys, data: action.payload, loading: false}}

        // получаем персон у контрагента
        case types.REQUEST_GET_PERSON_FOR_ORDERS:
            return {...state, persons: {...state.persons, loading: true}}
        case types.SUCCESS_GET_PERSON_FOR_ORDERS:
            return {...state, persons: {...state.persons, data: action.payload, loading: false}}

        // получаем  водителей по борту
        case types.REQUEST_GET_DRIVERS_FOR_ORDERS:
            return {...state, drivers: {...state.drivers, loading: true}}
        case types.SUCCESS_GET_DRIVERS_FOR_ORDERS:
            return {...state, drivers: {...state.drivers, data: action.payload, loading: false}}

        // получаем кординаты и текст
        case types.REQUEST_GET_COORDINATES_FOR_ORDERS:
            let act = action.payload
            return {...state, [act.reduxField]: {...state[act.reduxField], loading: true, activeInput: act.activeInput}}
        case types.SUCCESS_GET_COORDINATES_FOR_ORDERS:
            act = action.payload
            return {
                ...state,
                [act.reduxField]: {
                    ...state[act.reduxField],
                    data: act.payload,
                    loading: false,
                    activeInput: act.activeInput
                }
            }

        // получаем пассажира
        case types.SUCCESS_GET_PASSENGER_FOR_ORDERS:
            return {...state, passenger: action.payload}

        // получаем сумму
        case types.SUCCESS_GET_SUM_FOR_ORDERS:
            return {...state, sum: action.payload}

        // получаем журнал
        case types.REQUEST_GET_AUDIT_FOR_ORDERS:
            return {...state, audit: {...state.audit, loading: true}}
        case types.SUCCESS_GET_AUDIT_FOR_ORDERS:
            return {...state, audit: {...state.audit, loading: false, data: action.payload}}

        // получаем трэк номер 1 компании
        case types.REQUEST_GET_MAPTRACK_FOR_ORDERS:
            return {...state, mapTrackLoading: true, mapTrack: initialState.mapTrack}
        case types.CLEAR_FIELDS_MAPTRACK_FOR_ORDERS:
            return {...state, mapTrack: initialState.mapTrack, mapTrackLoading: true}
        case types.SUCCESS_GET_MAPTRACK_FOR_ORDERS:
            return {...state, mapTrack: action.payload, mapTrackLoading: false}

        // получаем трэк номер 1 компании
        case types.REQUEST_GET_TAXIMETER_FOR_ORDERS:
            return {...state, taximeter: {...state.taximeter, loading: true, data: {}}}
        case types.SUCCESS_GET_TAXIMETER_FOR_ORDERS:
            return {...state, taximeter: {...state.taximeter, loading: false, data: action.payload}}

        //get one order
        case types.REQUEST_ONE_ORDER:
            return {...state, oneOrder: {...state.oneOrder, loading: true}}
        case types.GET_ONE_ORDER_SUCCESS:
            return {...state, oneOrder: {...state.oneOrder, loading: false, data: action.payload}};
        case types.GET_ONE_ORDER_ERROR:
            console.log("Get one order error! ", action.payload);
            return {...state, oneOrder: {data: {}, loading: false, error: "ERROR"}};
        case types.CLEAR_ONE_ORDER:
            return {...state, oneOrder: {data: {}, loading: false}};
        case types.CLEAR_TAXIMETER:
            return {...state, taximeter: {data: {}, loading: false}};
        case types.SUCCESS_GET_DRIVERTRACK_FOR_ORDERS:
            return {...state, trackedDriverCoord: action.payload};





        // --- logout from systeam
        case 'LOGOUT_FROM_SYSTEM':
            return initialState

        default:
            return state
    }
}

export default ordersReducer