import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters';

const initialState = { 
	filter: { 
		status: '0', // поиск по колонке   
		rightFilter: { 
		 
		}, 
	}, 
	data: [],  // список машин
	loaded: false, // при загрузки машин
	create: { // для работы при создании 
		loaded: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loaded: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loaded: false,  
		error: false,   
		success: false,   
	},
	windowCreateOpened: false, 
	windowUpdateOpened: false,  
	rules: { // правила для фото 
		loading: false,  
		data: [],      
	}, 
	driverPhotos: { // фото для одного водителя
		loading: false,  
		data: [],      
	}, 
	agreeDisagreeWithPhotos: { // принимаем или отклоняем фото водилы
		loading: false,  
		error: false,  
		success: false  
	}, 
	rulesChangeProgress: { // правила для фото изменение
		loading: false,  
		error: false,  
		success: false      
	}, 
}

export default (state=initialState, action) => {
 
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_PHOTOSINSP_STATUS:   
			return {...state, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_PHOTOSINSP_SEARCH: 
			return {...state, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}}

		case types.CHANGE_RIGHT_FILTER_PHOTOSINSP:  
			return {...state, filter: {...state.filter, rightFilter: action.payload}}

		case types.USE_FILTER_SUCCESS_PHOTOSINSP:   
			return {...state, loaded: true, data: filterDataForId(action.payload)} 
 

		// --- Async get ->>
		case types.REQUEST_GET_PHOTOSINSP:  
			return {...state, loaded: false}

		case types.GET_DATA_PHOTOSINSP_SUCCESS:     
			return {...state, loaded: true, data: filterDataForId(action.payload)} 

		case types.GET_DATA_PHOTOSINSP_ERROR: 
			return {...state, loaded: true, data: [], error: action.payload} 


	// --- Async delete data ->>
	case types.REQUEST_DELETE_PHOTOSINSP: 
		return {...state, delete: {...state.delete, loaded: true}}  
	case types.DELETE_PHOTOSINSP_SUCCESS:  
		newData = state.data.filter(el=> el.id !== action.payload) 
		return {...state, delete: {...state.delete, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
	case types.DELETE_PHOTOSINSP_ERROR: 
		return {...state, delete: {...state.delete, loaded: false, error: action.error, success: false}}  
	case types.CLEAR_DELETE_FIELDS_PHOTOSINSP: 
		return {...state, delete: {...state.delete, loaded: false, error: false, success: false}} // initial 


		// --- Async update ->>
	case types.REQUEST_UPDATE_PHOTOSINSP: 
		return {...state, update: {...state.update, loaded: true}}  
	case types.UPDATE_PHOTOSINSP_SUCCESS:
			let result = []
			newData = state.data.map(el => { 
			if(el.id === action.payload[0].id) el = action.payload[0]
			return result.push(el)
		})
			if(action.payload.length > 1) result = [...result, action.payload[1]]
		return {...state, update: {...state.update, loaded: false, success: true, error: false}, data: result}  
	case types.UPDATE_PHOTOSINSP_ERROR: 
		return {...state, update: {...state.update, loaded: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_PHOTOSINSP: 
		return {...state, update: {...state.update, loaded: false, error: false, success: false}} // initial 


		// --- Async create ->>
		case types.REQUEST_CREATE_PHOTOSINSP: 
			return {...state, create: {...state.create, loaded: true}}  
		case types.CREATE_PHOTOSINSP_SUCCESS: 
			let newData = state.data.concat(action.payload) 
			return {...state, create: {...state.create, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_PHOTOSINSP_ERROR: 
			return {...state, create: {...state.create, loaded: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS_PHOTOSINSP: 
			return {...state, create: {...state.create, loaded: false, error: false, success: false}} // initial 
 

		// показ окон
		case types.SHOW_WINDOW_ADD_PHOTOSINSP:    
			return {...state, windowCreateOpened: action.payload} 
		case types.SHOW_WINDOW_UPDATE_PHOTOSINSP:    
			return {...state, windowUpdateOpened: action.payload} 

		
		// --- requests rules
			// get
		case types.REQUEST_GET_RULES_PHOTOSINSP: 
			return {...state, rules: {...state.rules, loading: true,}}

		case types.GET_RULES_PHOTOSINSP_SUCCESS:   
			return {...state, rules: {...state.rules, loading: false, data: action.payload}}

			// change
		case types.REQUEST_CHANGE_RULES_PHOTOSINSP: 
			return {...state, rulesChangeProgress: {...state.rulesChangeProgress, loading: true}}

		case types.CHANGE_RULES_PHOTOSINSP_SUCCESS:   
			return {...state, rulesChangeProgress: {...state.rulesChangeProgress, loading: false, error: false, success: true}}
			
		case types.CHANGE_RULES_PHOTOSINSP_ERROR:   
			return {...state, rulesChangeProgress: {...state.rulesChangeProgress, loading: false, error: true,  success: false }}
			
		case types.CLEAR_CHANGE_RULES_FIELDS_PHOTOSINSP:   
			return {...state, rulesChangeProgress: initialState.rulesChangeProgress}
   
			// get photos for one
		case types.REQUEST_GET_DRIVERPHOTOS_PHOTOSINSP: 
			return {...state, driverPhotos: {...state.driverPhotos, loading: true,}}

		case types.GET_DRIVERPHOTOS_PHOTOSINSP_SUCCESS:   
			return {...state, driverPhotos: {...state.driverPhotos, loading: false, data: action.payload}}
   
			//  agree Disagree Width Photos 
			case types.REQUEST_AGREE_DISAGREE_PHOTOS_PHOTOSINSP: 
				return {...state, agreeDisagreeWithPhotos: {...state.agreeDisagreeWithPhotos, loading: true}}
	
			case types.SUCCESS_AGREE_DISAGREE_PHOTOS_PHOTOSINSP:   
			// !!! запросить у бэка ответ-obj на смену статуса в общем списке data... action.payload: {status: "1", currentDriverId: 5521}
				newData = state.data.map(elem => {
					let el = Object.assign({}, elem)
					if(el.currentDriverId === action.payload.currentDriverId) el.status = action.payload.status
					return el
				})
				return {...state, data: filterDataForId(newData),
					 agreeDisagreeWithPhotos: {...state.agreeDisagreeWithPhotos, loading: false, error: false, success: true}}
				
			case types.ERROR_AGREE_DISAGREE_PHOTOS_PHOTOSINSP:   
				return {...state, agreeDisagreeWithPhotos: {...state.agreeDisagreeWithPhotos, loading: false, error: true,  success: false }}
				
			case types.CLEAR_GREE_DISAGREE_PHOTOS_FIELDS_PHOTOSINSP:   
				return {...state, agreeDisagreeWithPhotos: initialState.agreeDisagreeWithPhotos}
 

		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  

		default: 
			return state
	}
}
 