import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_CORPCLIENTS_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_CORPCLIENTS_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_CORPCLIENTS, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_CORPCLIENTS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_CORPCLIENTS, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_CORPCLIENTS_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_CORPCLIENTS_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_CORPCLIENTS, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_CORPCLIENTS_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_CORPCLIENTS_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_CORPCLIENTS, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_CORPCLIENTS_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_CORPCLIENTS_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_CORPCLIENTS, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_CORPCLIENTS_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_CORPCLIENTS_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_CORPCLIENTS, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_CORPCLIENTS, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_CORPCLIENTS}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_CORPCLIENTS}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_CORPCLIENTS})   

// --- Windows request  
// показ окон для создание апдейта пассажиров
export const showWindowPassengersAdd = (payload) => ({type: types.SHOW_WINDOW_PASSENGERS_ADD_CORPCLIENTS, payload})
export const showWindowPassengersUpdate = (payload) => ({type: types.SHOW_WINDOW_PASSENGERS_UPDATE_CORPCLIENTS, payload}) 
//  получаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_CORPCLIENTS, payload})  
export const getGetAuditSuccess = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_CORPCLIENTS, payload}) 

// --- пассажиры 
// get  
export const requestGetPassengers = (payload, filterData) => ({type: types.REQUEST_GET_PASSENGERS_FOR_CORPCLIENTS, payload, filterData}) 
export const getPassengersSuccess = (payload) => ({type: types.SUCCESS_GET_PASSENGERS_FOR_CORPCLIENTS, payload}) 
//create
export const requestCreatePassengers = (payload) => ({type: types.REQUEST_CREATE_PASSENGERS_CORPCLIENTS, payload})  
export const successCreatePassengers = (payload) => ({type: types.SUCCESS_CREATE_PASSENGERS_CORPCLIENTS, payload})  
export const errorCreatePassengers = (payload) => ({type: types.ERROR_CREATE_PASSENGERS_CORPCLIENTS, payload})  
export const clearCreatePassengersFields = (payload) => ({type: types.CLEAR_CREATE_PASSENGERS_FOR_CORPCLIENTS, payload})  
//update
export const requestUpdatePassengers = (payload) => ({type: types.REQUEST_UPDATE_PASSENGERS_CORPCLIENTS, payload})  
export const successUpdatePassengers = (payload) => ({type: types.SUCCESS_UPDATE_PASSENGERS_CORPCLIENTS, payload})  
export const errorUpdatePassengers = (payload) => ({type: types.ERROR_UPDATE_PASSENGERS_CORPCLIENTS, payload})  
export const clearUpdatePassengersFields = (payload) => ({type: types.CLEAR_UPDATE_PASSENGERS_FOR_CORPCLIENTS, payload})  
// del
export const requestDeletePassengers = (payload) => ({type: types.REQUEST_DEL_PASSENGERS_CORPCLIENTS, payload})  
export const successDeletePassengers = (payload) => ({type: types.SUCCESS_DEL_PASSENGERS_CORPCLIENTS, payload})  
export const errorDeletePassengers = (payload) => ({type: types.ERROR_DEL_PASSENGERS_CORPCLIENTS, payload})  
export const clearDeletePassengersFields = (payload) => ({type: types.CLEAR_DELETE_PASSENGERS_FOR_CORPCLIENTS, payload})  
// audit passanger
export const requestGetAuditPassengers  = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS, payload})  
export const successAuditPassengers = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS, payload})    