import axios from 'axios'
 

export default { 
	getData: (data) => {
		if(data.test){
			return axios.post(`https://park.multipassme.ru/webapi/v1/map/data`, data.filter, {
				headers: { Authorization: "Bearer " + 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIxMjAwIiwic3ViIjoiTGFwaW5BIiwiaWF0IjoxNTcxODM4MjkzLCJleHAiOjE1NzE5MjQ2OTMsInBlciI6eyJvcmRlcnMiOjMxLCJjbGllbnRzIjowLCJjb3JwQ2xpZW50cyI6MCwiY2xpZW50c0JsYWNrTGlzdCI6MCwibWFwIjozMSwibWFpbExpc3QiOjAsImRpYWxvZ3MiOjAsImRyaXZlcnMiOjMxLCJwaG90b0luc3BlY3Rpb24iOjAsImRyaXZlcnNDb250cm9sIjowLCJkcml2ZXJzTm90aWNlIjowLCJkcml2ZXJzQmxhY2tMaXN0IjozMSwiY29sdW1ucyI6MzEsImNhcnMiOjMxLCJjYXJzQ29udHJvbCI6MCwiY2Fyc1NlcnZpY2UiOjAsImNhcnNJbnN1cmFuY2UiOjAsImNsYXNzVGF4aSI6MCwidHJhbnNhY3Rpb25zIjowLCJiYWxhbmNlQ3JlZGl0IjowLCJiYWxhbmNlRGViZXQiOjAsImJhbGFuY2UiOjAsImJhbGFuY2VEZWJldEZ1ZWwiOjAsImJhbGFuY2VEZWJldENhcndhc2giOjAsInJlcG9ydEFnZW50IjowLCJyZXBvcnRNb25leSI6MCwicmVwb3J0RHJpdmVycyI6MCwicmVwb3J0Q2FycyI6MCwicmVwb3J0TWFuYWdlciI6MzEsInNldHRpbmdBcHBzIjozMSwidGFyaWZmRWRpdCI6MzEsInNlcnZpY2VzIjowLCJjcmVhdGluZ0NvbXBhbnkiOjAsImNyZWF0aW5nVXNlcnMiOjMxLCJ1c2Vyc1JvbGVzIjoxNX0sImNvbSI6MzMwMCwiZmlvIjoi0JvQsNC_0LjQvSDQkNC70LXQutGB0LXQuSDQktCw0LvQtdGA0YzQtdCy0LjRhyIsImluZm8iOnsiY29tcGFueU5hbWUiOiLQotCw0LrRgdC4INCg0LjRgtC8Iiwicm9vdENvbXBhbnkiOnRydWV9fQ.4g7ho0cXQWE-C67U67PTAcqbipHfCCKcZc7TAPUqRHYdQEyqWrh8R2Z-PyhNarWc0CbCQUocvdT0DG5WHDx8JQ' }
			})
			.then(r => r.data)
		} 
		else return axios.post(`/map/data`, data.filter).then(r => r.data)
	}, 
} 