import reportManager from './reportManager/saga';
import reportGiveOrder from "./reportGiveOrder/saga"; 
import reportRecieveOrder from "./reportRecieveOrder/saga"; 
import reportAgent from "./reportAgent/saga";

export default [ 
  ...reportManager,
  ...reportGiveOrder,
  ...reportRecieveOrder,
  ...reportAgent  
]
 