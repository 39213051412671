import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/controlRoom/orders';
import API_DRIVER from '../../../../api/dictionaries/drivers';
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_ORDERS, requestGetData),
	takeLatest(types.REQUEST_GET_DATA_COMPANIES, requestGetCompaniesData),  
	takeLatest(types.REQUEST_GET_DRIVER_FOR_ORDERS, getOneDriver),  
	takeLatest(types.REQUEST_CREATE_ORDERS, createData),    
	takeLatest(types.REQUEST_DELETE_ORDERS, deleteData),    
	takeLatest(types.REQUEST_UPDATE_ORDERS, updateData),
	takeLatest(types.SEND_COMPANIES_DATA, sendCompaniesData), 
	takeLatest(types.SEND_ORDER, sendOrder), 
  // takeLatest(types.REQUEST_CHANGE_ORDERS_STATUS, useFilter),   // меняем статус только ЛОКАЛЬНО 
  takeLatest(types.REQUEST_CHANGE_ORDERS_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_ORDERS, useFilter),  
	// in widnows request  
	takeLatest(types.REQUEST_ONE_ORDER, getOneOrder),
	takeLatest(types.REQUEST_GET_COUNTERPARTY_FOR_ORDERS, getCounterparty),  
	takeLatest(types.REQUEST_GET_PERSON_FOR_ORDERS, getPersons),  
	takeLatest(types.REQUEST_GET_PASSENGER_FOR_ORDERS, getPassenger),  
	takeLatest(types.REQUEST_GET_COORDINATES_FOR_ORDERS, getCoordinate),  
	takeLatest(types.REQUEST_GET_DRIVERS_FOR_ORDERS, getDrivers),  
	takeLatest(types.REQUEST_GET_SUM_FOR_ORDERS, getSum),  
	takeLatest(types.REQUEST_GET_AUDIT_FOR_ORDERS, getAudit),  
	takeLatest(types.REQUEST_GET_MAPTRACK_FOR_ORDERS, getMapTrack),  
	takeLatest(types.REQUEST_GET_TAXIMETER_FOR_ORDERS, requestGetTaximeter),  
	takeLatest(types.REQUEST_GET_DRIVERTRACK_FOR_ORDERS, requestDriverTrack)
]

function* sendOrder(action){
	try{
		const data = yield call(API.sendOrderToExchanger, action.payload);
		yield put(actions.sendOrderSuccess(data));
	}catch(error){
		console.log("sendOrder ORDERS", error);
		yield put(actions.sendOrderError(error));
	}
}

function* getOneOrder(action){
	try{
		const data = yield call(API.getOneOrder, action.payload);
		yield put(actions.getOneOrderSuccess(data));
	}catch(error){
		yield put(actions.getOneOrderError(error));
		console.log("getOneOrder ORDERS", error);
	}
}

function* sendCompaniesData(action){
	try {
		const data = yield call(API.sendCompaniesData, action.payload);
		yield put(actions.sendCompaniesDataSuccess(data))
	} catch(error){
		console.error('(sendCompaniesError ORDERS)', error);
		yield put(actions.sendCompaniesDataError(error))
	}
}

function* requestGetCompaniesData(action) {
	try {   
		const data = yield call(API.getCompanies, action.payload);    
		yield put(actions.getDataCompaniesSuccess(data))
		
	} catch (error) {  
		console.error('(getDataCompaniesError ORDERS)', error);
		yield put(actions.getDataCompaniesError(error))
	}
}

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError ORDERS)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {   
		yield put(actions.useFilterSuccess([]))
	}
}
 
//
function* getOneDriver(action) {
	try {     
		const data = yield call(API_DRIVER.getOneDriver, action.payload);   
		yield put(actions.getOneDriverSuccess(data))
		
	} catch (error) {  
		yield put(actions.getOneDriverSuccess({}))
		console.error('(getOneDriver ORDERS) ', error); 
	}
}  
 
// 3
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);  
		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error('(createData ORDERS) ', error); 
		yield put(actions.createDataError(error))
	}
}
 
// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR ORDERS) ', error); 
		yield put(actions.deleteDataError(error))
	}
}
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR ORDERS) ', error); 
		yield put(actions.updateDataError(error))
	}
}
 
// 
function* getCounterparty(action) {
	try {   
		const data = yield call(API.getCounterparty, action.payload);  
		yield put(actions.getCounterpartysSuccess(data))   
		
	} catch (error) {  
		console.error('(getCounterparty FOR ORDERS) ', error); 
		yield put(actions.getCounterpartysSuccess([]))
	}
}

//  
function* getPersons(action) {
	try {   
		const data = yield call(API.getPersons, action.payload);  
		yield put(actions.getPersonsSuccess(data))   
		
	} catch (error) {  
		console.error('(getPersons FOR ORDERS) ', error); 
		yield put(actions.getPersonsSuccess([]))
	}
}

//  
function* getPassenger(action) {
	try {   
		const data = yield call(API.getPassenger, action.payload);  
		yield put(actions.getPassengerSuccess(data))   
		
	} catch (error) {  
		console.error('(getPassenger FOR ORDERS) ', error); 
		yield put(actions.getPassengerSuccess([]))
	}
}

//  activeInput - для объектов  to
function* getCoordinate(action) {
	try {   
		const data = yield call(API.getCoordinate, action.payload.payload);   
		yield put(actions.successGetTextAndCoordinates({payload: data, reduxField: action.payload.reduxField, activeInput: action.payload.activeInput}))   
		
	} catch (error) {  
		console.error('(getCoordinate FOR ORDERS) ', error); 
		yield put(actions.successGetTextAndCoordinates({payload: [], reduxField: action.payload.reduxField, activeInput: action.payload.activeInput}))
	}
}

//  
function* getDrivers(action) {
	try {   
		const data = yield call(API_DRIVER.getData, action.payload);  
		yield put(actions.getGetDriversSuccess(data))   
		
	} catch (error) {  
		console.error('(getDrivers FOR ORDERS) ', error); 
		yield put(actions.getGetDriversSuccess([]))
	}
}

//  
function* getSum(action) {
	console.log(action)
	try {   
		const data = yield call(API.getSum, action.payload);   
		yield put(actions.getGetSumSuccess(data))   
		
	} catch (error) {  
		console.error('(getGetSumSuccess FOR ORDERS) ', error); 
		yield put(actions.getGetSumSuccess(''))
	}
}

//  
function* getAudit(action) {
	try {   
		const data = yield call(API.getAudit, action.payload);   
		yield put(actions.getGetAuditSuccess(data))   
		
	} catch (error) {  
		console.error('(getGetAuditSuccess FOR ORDERS) ', error); 
		yield put(actions.getGetAuditSuccess([]))
	}
}

//  
function* getMapTrack(action) {
	try {   
		const data = yield call(API.getMapTrack, action.payload);    
		yield put(actions.getMapTrackSuccess(data))   
		
	} catch (error) {   
		yield put(actions.getMapTrackSuccess({}))
	}
}

//  
function* requestGetTaximeter(action) {
	try {   
		const data = yield call(API.getTaximeter, action.payload);    
		yield put(actions.getTaximeterSuccess(data))   
		
	} catch (error) {   
		yield put(actions.getTaximeterSuccess({}))
	}
}

//  
function* requestDriverTrack(action) {
	try {   
		const data = yield call(API.getDriverTrack, action.payload);   
		// if(data)
		yield put(actions.getDriverTrackSuccess(data))   
		
	} catch (error) {   
		// yield put(actions.getTaximeterSuccess({}))
	}
}

