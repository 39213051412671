import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/admin/tariffEdit';  
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_TARIFFEDIT, requestGetData),   
	takeLatest(types.REQUEST_GET_ONE_TARIFFEDIT, requestGetOne),      
	takeLatest(types.REQUEST_CREATE_TARIFFEDIT, createData),    
	takeLatest(types.REQUEST_DELETE_TARIFFEDIT, deleteData),    
	takeLatest(types.REQUEST_UPDATE_TARIFFEDIT, updateData),  
	takeLatest(types.REQUEST_GET_AREAPARAMS_TARIFFEDIT, getAreaParams),   

  takeLatest(types.REQUEST_CHANGE_TARIFFEDIT_STATUS, useFilter),   
  takeLatest(types.REQUEST_CHANGE_TARIFFEDIT_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_TARIFFEDIT, useFilter),   
]

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError TARIFFEDIT)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		console.error('(useFilter TARIFFEDIT) ', error); 
	}
}
  
 
// 3
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);  
		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error('(createData TARIFFEDIT) ', error); 
		yield put(actions.createDataError(error))
	}
}
 
// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR TARIFFEDIT) ', error); 
		yield put(actions.deleteDataError(error))
	}
}
 
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR TARIFFEDIT) ', error); 
		yield put(actions.updateDataError(error))
	}
}
 
// 
function* getAreaParams(action) {
	try {   
		const data = yield call(API.getAreaParams, action.payload);  
		yield put(actions.getAreaParametersSuccess(data))   
		
	} catch (error) {   
		yield put(actions.getAreaParametersSuccess([]))
	}
} 
 
// 
function* requestGetOne(action) {
	try {   
		const data = yield call(API.getOne, action.payload);   
		yield put(actions.successGetOne(data))   
		
	} catch (error) {   
		yield put(actions.successGetOne({}))
	}
} 