// filter
export const REQUEST_CHANGE_COLUMNS_STATUS = 'REQUEST_CHANGE_COLUMNS_STATUS'
export const REQUEST_CHANGE_COLUMNS_SEARCH = 'REQUEST_CHANGE_COLUMNS_SEARCH'
export const CHANGE_RIGHT_FILTER_COLUMNS = 'CHANGE_RIGHT_FILTER_COLUMNS' 
export const USE_FILTER_SUCCESS_COLUMNS = 'USE_FILTER_SUCCESS_COLUMNS' 
// get
export const REQUEST_GET_COLUMNS = 'REQUEST_GET_COLUMNS'
export const GET_DATA_COLUMNS_SUCCESS = 'GET_DATA_COLUMNS_SUCCESS' 
export const GET_DATA_COLUMNS_ERROR = 'GET_DATA_COLUMNS_ERROR' 
// delete 
export const REQUEST_DELETE_COLUMNS = 'REQUEST_DELETE_COLUMNS'
export const DELETE_COLUMNS_SUCCESS = 'DELETE_COLUMNS_SUCCESS' 
export const DELETE_COLUMNS_ERROR = 'DELETE_COLUMNS_ERROR' 
// update 
export const REQUEST_UPDATE_COLUMNS = 'REQUEST_UPDATE_COLUMNS'
export const UPDATE_COLUMNS_SUCCESS = 'UPDATE_COLUMNS_SUCCESS' 
export const UPDATE_COLUMNS_ERROR = 'UPDATE_COLUMNS_ERROR' 
// create 
export const REQUEST_CREATE_COLUMNS = 'REQUEST_CREATE_COLUMNS'
export const CREATE_COLUMNS_SUCCESS = 'CREATE_COLUMNS_SUCCESS' 
export const CREATE_COLUMNS_ERROR = 'CREATE_COLUMNS_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_COLUMNS = 'SHOW_WINDOW_ADD_COLUMNS' 
export const SHOW_WINDOW_UPDATE_COLUMNS = 'SHOW_WINDOW_UPDATE_COLUMNS'  
// get cars in window
export const REQUEST_GET_CARS_FOR_COLUMNS = 'REQUEST_GET_CARS_FOR_COLUMNS' 
export const GET_CARS_FOR_COLUMNS_SUCCESS = 'GET_CARS_FOR_COLUMNS_SUCCESS'  
// очищение реквестов
export const CLEAR_CREATE_FIELDS_COLUMNS = 'CLEAR_CREATE_FIELDS_COLUMNS' 
export const CLEAR_UPDATE_FIELDS_COLUMNS = 'CLEAR_UPDATE_FIELDS_COLUMNS'  
export const CLEAR_DELETE_FIELDS_COLUMNS = 'CLEAR_DELETE_FIELDS_COLUMNS'  