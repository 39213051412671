import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/dictionaries/drivers';
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_DRIVERS, requestGetData),  
	takeLatest(types.REQUEST_CREATE_DRIVER, createData),  
	takeLatest(types.REQUEST_GET_CARS_FOR_DRIVERS, requestGetCars),  
	takeLatest(types.REQUEST_DELETE_DRIVERS, deleteData),  
	takeLatest(types.REQUEST_GET_ONECAR_FOR_DRIVERS, getOneCar),  
	takeLatest(types.REQUEST_UPDATE_DRIVERS, updateData),  

  takeLatest(types.REQUEST_CHANGE_DRIVER_STATUS, useFilter),   
  takeLatest(types.REQUEST_CHANGE_DRIVER_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_DRIVERS, useFilter),   
	// --- Window requests
  takeLatest(types.REQUEST_GET_AUDIT_FOR_DRIVERS, requestGetAudit),   
  takeLatest(types.REQUEST_GET_FILTERPARAMS_DRIVERS, requestGetAuditParams),   
  takeLatest(types.REQUEST_GET_DRIVERORDERS_FOR_DRIVERS, getDriverOrders),   
  takeLatest(types.REQUEST_GET_LOGEVENTS_FOR_DRIVERS, getLogEvents),   
	takeLatest(types.REQUEST_GET_FINANCES_FOR_DRIVERS, getFinances),  
	takeLatest(types.REQUEST_GET_DRIVERPHOTOS_DRIVERS, requestGetDriverPhotos),  
	// finance 
  takeLatest(types.REQUEST_CREATE_REPLENISH_FOR_DRIVERS, createReplenish),   
	takeLatest(types.REQUEST_CREATE_WITHDRAW_FOR_DRIVERS, createWithdraw),  
	// adapter 
	takeLatest(types.REQUEST_GET_OVEDRIVER_DRIVERS, requestGetOneDriver),
	takeLatest(types.REQUEST_GET_DRIVER_INFO, requestGetDriverInfo)
]
 
// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError drivers)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}

// 3
function* requestGetCars(action) {
	try {   
		const data = yield call(API.getCars, action.payload);  
		yield put(actions.getCarsForDriversSuccess(data))
		
	} catch (error) { 
		console.error('(useFilter drivers) ', error); 
		yield put(actions.getCarsForDriversSuccess([]))
	}
}
 
// 3
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);    
		yield put(actions.createDataSuccess(data))
		
	} catch (error) {    
		yield put(actions.createDataError(error))
	}
}
 
// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR drivers) ', error); 
		yield put(actions.deleteDataError(error))
	}
}

// 
function* getOneCar(action) {
	try {   
		const data = yield call(API.getOneCar, action.payload);  
		yield put(actions.getOneCarSuccess(data))   
		
	} catch (error) {  
		console.error('(getOneCar FOR drivers) ', error); 
		yield put(actions.getOneCarSuccess({}))
	}
}

// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR drivers) ', error); 
		yield put(actions.updateDataError(error))
	}
}


// --- Window requests
// получаем аудит
function* requestGetAudit(action) {
	try {    
		const data = yield call(API.getAudit, action.payload);  
		yield put(actions.successGetAudit(data))   
		
	} catch (error) {   
		yield put(actions.successGetAudit([]))
	}
}
// получаем фильтры параметра аудита
function* requestGetAuditParams(action) {
	try {    
		const data = yield call(API.getAuditParams, action.payload);  
		yield put(actions.successGetAuditParams(data))   
		
	} catch (error) {   
		yield put(actions.successGetAuditParams([]))
	}
}
// получаем аудит
function* getDriverOrders(action) {
	try {     
		const data = yield call(API.getDriverOrders, action.payload);  
		yield put(actions.successGetDriverOrders(data))   
		
	} catch (error) {    
		yield put(actions.successGetDriverOrders([]))
	}
}
//  
function* getLogEvents(action) {
	try {     
		const data = yield call(API.getLogEvents, action.payload);  
		yield put(actions.successGetLogEvents(data))   
		
	} catch (error) {    
		yield put(actions.successGetLogEvents([]))
	}
}
//  
function* getFinances(action) { 
	try {     
		const data = yield call(API.getFinances, action.filterData);  
		yield put(actions.successGetFinances(data))   
		
	} catch (error) {    
		yield put(actions.successGetFinances([]))
	}
}
//  
function* createReplenish(action) {
	try {     
		const data = yield call(API.createReplenish, action.payload); 
		yield put(actions.successCreateReplenish({balance: data, id: action.payload.id}))   
		
	} catch (error) {     
		yield put(actions.errorCreateReplenish(error))
	}
}
//  
function* createWithdraw(action) {
	try {     
		const data = yield call(API.createWithdraw, action.payload);  
		// тк невозможно получить последнию запись с бэка - делаем запрос н получении всех в случаи успеха
		actions.requestGetFinances(data) 
		yield put(actions.successCreateWidhdrow({balance: data, id: action.payload.id}))   
		
	} catch (error) {    
		yield put(actions.errorCreateWidhdrow(error))
	}
}

function* requestGetDriverPhotos(action) {
	try {    
		const data = yield call(API.getDriverPhotos, action.payload);    
		yield put(actions.getDriverPhotosSuccess(data))
		
	} catch (error) {   
		yield put(actions.getDriverPhotosSuccess([]))
	}
}

function* requestGetOneDriver(action) {
	try {    
		const data = yield call(API.getOneDriver, action.payload);    
		yield put(actions.getOneDriverSuccess(data))
		
	} catch (error) {   
		yield put(actions.getOneDriverSuccess({}))
	}
}

function* requestGetDriverInfo(action) {
	try {
		const data = yield call(API.getDriverInfo, action.payload);
		yield put(actions.getDriverInfoSuccess(data))

	} catch (error) {
		yield put(actions.getDriverInfoSuccess({}))
	}
}