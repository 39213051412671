import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[formOrder] requestChangeStatus')
export const requestChangeParamSearch = createAction('[formOrder] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[formOrder] requestChangeRightFilter')
export const changeAnyFilter = createAction('[reportCarsOnline] changeAnyFilter')

// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[formOrder] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[formOrder] requestGetDataPage')
export const getDataPageSuccess = createAction('[formOrder] getDataPageSuccess')
export const getDataPageError = createAction('[formOrder] getDataPageError')
// delete
export const requestDeleteData = createAction('[formOrder] requestDeleteData')
export const deleteDataSuccess = createAction('[formOrder] deleteDataSuccess')
export const deleteDataError = createAction('[formOrder] deleteDataError')
// create
export const sendModalData = createAction('[formOrder] sendModalData')
export const sendModalSuccess = createAction('[formOrder] sendModalSuccess')
export const sendModalError = createAction('[formOrder] sendModalError')
export const requestCreateData = createAction('[formOrder] requestCreateData')
export const createDataSuccess = createAction('[formOrder] createDataSuccess')
export const createDataError = createAction('[formOrder] createDataError') 
// update
export const requestUpdateData = createAction('[formOrder] requestUpdateData') 
export const updateDataSuccess = createAction('[formOrder] updateDataSuccess') 
export const updateDataError = createAction('[formOrder] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[formOrder] showWindowAdd') 
export const showWindowUpdate = createAction('[formOrder] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[formOrder] clearModalFields')
export const clearCreateFields = createAction('[formOrder] clearCreateFields') 
export const clearUpdateFields = createAction('[formOrder] clearUpdateFields') 
export const clearDeleteFields = createAction('[formOrder] clearDeleteFields') 
export const clearRightFilterFields = createAction('[formOrder] clearRightFilterFields')

// pagination
export const changePagination = createAction('[formOrder] changePagination')

// for window create in formOrder
export const addAlias = createAction('[formOrder] addAlias')
export const removeAlias = createAction('[formOrder] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneOrder = createAction('[formOrder] requestGetOneOrder')  
export const getOneOrderSuccess = createAction('[formOrder] getOneOrderSuccess') 
export const getOneOrderError = createAction('[formOrder] getOneOrderError') 

export const changeLoading = createAction('[formOrder] changeLoading');
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 