import blacklist from './blacklistPage/reducer'; 
import corpClients from './corpClients/reducer'; 
import orders from './ordersPage/reducer'; 
import map from './map/reducer'; 
import clients from './clients/reducer'; 
import driverBlacklistPage from './driverBlacklistPage/reducer'; 

export default (state={}, action) => {  
	return {
		blacklist: blacklist(state.blacklist, action),  
		driverBlacklistPage: driverBlacklistPage(state.driverBlacklistPage, action),  
		corpClients: corpClients(state.corpClients, action),  
		orders: orders(state.orders, action),  
		map: map(state.map, action),  
		clients: clients(state.clients, action),  
	} 
} 