// filter
export const REQUEST_CHANGE_CREATEUSER_STATUS = 'REQUEST_CHANGE_CREATEUSER_STATUS'
export const REQUEST_CHANGE_CREATEUSER_SEARCH = 'REQUEST_CHANGE_CREATEUSER_SEARCH'
export const CHANGE_RIGHT_FILTER_CREATEUSER = 'CHANGE_RIGHT_FILTER_CREATEUSER' 
export const USE_FILTER_SUCCESS_CREATEUSER = 'USE_FILTER_SUCCESS_CREATEUSER' 
export const CHANGE_ANY_FILTER_CREATEUSER = 'CHANGE_ANY_FILTER_CREATEUSER' // изменяем любой другой доп фильтр (не сайд-эффект)
// get
export const REQUEST_GET_CREATEUSER = 'REQUEST_GET_CREATEUSER'
export const GET_DATA_CREATEUSER_SUCCESS = 'GET_DATA_CREATEUSER_SUCCESS' 
export const GET_DATA_CREATEUSER_ERROR = 'GET_DATA_CREATEUSER_ERROR' 
// delete 
export const REQUEST_DELETE_CREATEUSER = 'REQUEST_DELETE_CREATEUSER'
export const DELETE_CREATEUSER_SUCCESS = 'DELETE_CREATEUSER_SUCCESS' 
export const DELETE_CREATEUSER_ERROR = 'DELETE_CREATEUSER_ERROR' 
// update 
export const REQUEST_UPDATE_CREATEUSER = 'REQUEST_UPDATE_CREATEUSER'
export const UPDATE_CREATEUSER_SUCCESS = 'UPDATE_CREATEUSER_SUCCESS' 
export const UPDATE_CREATEUSER_ERROR = 'UPDATE_CREATEUSER_ERROR' 
// create 
export const REQUEST_CREATE_CREATEUSER = 'REQUEST_CREATE_CREATEUSER'
export const CREATE_CREATEUSER_SUCCESS = 'CREATE_CREATEUSER_SUCCESS' 
export const CREATE_CREATEUSER_ERROR = 'CREATE_CREATEUSER_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_CREATEUSER = 'SHOW_WINDOW_ADD_CREATEUSER' 
export const SHOW_WINDOW_UPDATE_CREATEUSER = 'SHOW_WINDOW_UPDATE_CREATEUSER'  
// get cars in window
export const REQUEST_GET_CARS_FOR_CREATEUSER = 'REQUEST_GET_CARS_FOR_CREATEUSER' 
export const GET_CARS_FOR_CREATEUSER_SUCCESS = 'GET_CARS_FOR_CREATEUSER_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_CREATEUSER = 'REQUEST_GET_ONECAR_FOR_CREATEUSER' 
export const GET_ONECAR_FOR_CREATEUSER_SUCCESS = 'GET_ONECAR_FOR_CREATEUSER_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_CREATEUSER = 'CLEAR_CREATE_FIELDS_CREATEUSER' 
export const CLEAR_UPDATE_FIELDS_CREATEUSER = 'CLEAR_UPDATE_FIELDS_CREATEUSER'  
export const CLEAR_DELETE_FIELDS_CREATEUSER = 'CLEAR_DELETE_FIELDS_CREATEUSER'    


// --- Windows request 
// get company
export const REQUEST_GET_COMPANIES_FOR_CREATEUSER = 'REQUEST_GET_COMPANIES_FOR_CREATEUSER'  
export const SUCCESS_GET_COMPANIES_FOR_CREATEUSER = 'SUCCESS_GET_COMPANIES_FOR_CREATEUSER'  
// get roles
export const REQUEST_GET_ROLES_FOR_CREATEUSER = 'REQUEST_GET_ROLES_FOR_CREATEUSER'  
export const SUCCESS_GET_ROLES_FOR_CREATEUSER = 'SUCCESS_GET_ROLES_FOR_CREATEUSER'  
//  поулчаем аудит
export const REQUEST_GET_AUDIT_FOR_CREATEUSER = 'REQUEST_GET_AUDIT_FOR_CREATEUSER'  
export const SUCCESS_GET_AUDIT_FOR_CREATEUSER = 'SUCCESS_GET_AUDIT_FOR_CREATEUSER'  

 