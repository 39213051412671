import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[reportAgent] requestChangeStatus')
export const requestChangeParamSearch = createAction('[reportAgent] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[reportAgent] requestChangeRightFilter')
export const changeAnyFilter = createAction('[reportAgent] changeAnyFilter')

// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[reportAgent] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[reportAgent] requestGetDataPage')
export const getDataPageSuccess = createAction('[reportAgent] getDataPageSuccess')
export const getDataPageError = createAction('[reportAgent] getDataPageError')
// get companies
export const requestGetCompaniesData = createAction('[reportAgent] requestGetCompaniesData')
export const getCompaniesDataSuccess = createAction('[reportAgent] getCompaniesDataSuccess')
export const getCompaniesDataError = createAction('[reportAgent] getCompaniesDataError')
// delete
export const requestDeleteData = createAction('[reportAgent] requestDeleteData')
export const deleteDataSuccess = createAction('[reportAgent] deleteDataSuccess')
export const deleteDataError = createAction('[reportAgent] deleteDataError')
// create
export const sendModalData = createAction('[reportAgent] sendModalData')
export const sendModalSuccess = createAction('[reportAgent] sendModalSuccess')
export const sendModalError = createAction('[reportAgent] sendModalError')
export const requestCreateData = createAction('[reportAgent] requestCreateData')
export const createDataSuccess = createAction('[reportAgent] createDataSuccess')
export const createDataError = createAction('[reportAgent] createDataError') 
// update
export const requestUpdateData = createAction('[reportAgent] requestUpdateData') 
export const updateDataSuccess = createAction('[reportAgent] updateDataSuccess') 
export const updateDataError = createAction('[reportAgent] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[reportAgent] showWindowAdd') 
export const showWindowUpdate = createAction('[reportAgent] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[reportAgent] clearModalFields')
export const clearCreateFields = createAction('[reportAgent] clearCreateFields') 
export const clearUpdateFields = createAction('[reportAgent] clearUpdateFields') 
export const clearDeleteFields = createAction('[reportAgent] clearDeleteFields') 
export const clearRightFilterFields = createAction('[reportAgent] clearRightFilterFields')

// pagination
export const changePagination = createAction('[reportAgent] changePagination')

// for window create in reportAgent
export const addAlias = createAction('[reportAgent] addAlias')
export const removeAlias = createAction('[reportAgent] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneCar = createAction('[reportAgent] requestGetOneCar')  
export const getOneCarSuccess = createAction('[reportAgent] getOneCarSuccess') 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 