import axios from 'axios'

const getData = [  
	{id: 0, name: 'bla bla', tariff: '1', cdate: '22.03.2019', udat: '03.06.2019'},  
] 

const getAreaParams = [  
	{value: '', label: 'Не выбран' },  
	{value: '0', label: 'ВАО' },  
	{value: '1', label: 'САО' },  
	{value: '2', label: 'АЭ Внуково' },  
	{value: '3', label: 'АЭ Домодедово' },  
] 

const getOne = {
	common: {
		name: 'Тариф мегого',     
		autoClassId: '',     
		tariff: '2',       
		selectedTariff: {
			'0': {  
				price: '500', // Стоимость поездки
				freeWait: '19', // Бесплатное ожидание (мин)
				paidWait: '54',  // Платное ожидание (руб в мин)
				onDowntime: '12',  // Простой, включённый  (мин)
				downtime: '9',  // Стоимость доп.минуты простоя (руб)
				speedDowntime: '39',  // Скорость, ниже которой включается простой (км/час):
			},
			'1': {  
				minPrice: '500', // Минимальная цена
				minutesIncluded: '3', // Включённые в заказ минуты
				minuteCost: '54',  // Стоимость дополнительной минуты
				freeWait: '9',  // Бесплатное ожидание (мин)
				paidWait: '11',  // Платное ожидание (руб в мин)
				costInCountry: '33',  // Стоимость км за городом (руб)
			},
			'2': {  
				minPrice: '500', // Минимальная цена
				kmIncluded: '23', // Включённые в заказ километры
				costAdditionalKm: '55', // Стоимость дополнительного километра
				freeWait: '9',  // Бесплатное ожидание (мин)
				paidWait: '11',  // Платное ожидание (руб в мин)
				costInCountry: '33',  // Стоимость км за городом (руб)
				onDowntime: '12',  // Простой, включённый  (мин)
				downtime: '9',  // Стоимость доп.минуты простоя (руб)
				speedDowntime: '39',  // Скорость, ниже которой включается простой (км/час):
			},
			'3': {  
				minPrice: '500', // Минимальная цена
				kmIncluded: '23', // Включённые в заказ километры
				minutesIncluded: '3', // Включённые в заказ минуты
				costAdditionalKm: '55', // Стоимость дополнительного километра
				minuteCost: '54',  // Стоимость дополнительной минуты
				freeWait: '9',  // Бесплатное ожидание (мин)
				paidWait: '11',  // Платное ожидание (руб в мин)
				costInCountry: '33',  // Стоимость км за городом (руб)
				onDowntime: '12',  // Простой, включённый  (мин)
				downtime: '9',  // Стоимость доп.минуты простоя (руб) 
				speedDowntime: '39',  // Скорость, ниже которой включается простой (км/час)
				costGiveCarCountry: '22',  // Стоимость подачи загород руб/километр
				freeKmCountry: '41',  // Бесплатных километров загородом
				freeKmEverywhere: '6',  // Бесплатных километров везде
			},
		}
	}, 
	fixed: {
		'0': {
			start: '1',
			end: '2',
			price: '221'
		},     
	}, 
}

export default {  
	getData: (filter) => axios.post(`/tariff/list`, filter).then(r => r.data),
	getOne: (data) => axios.post(`/tariffEdit/get/${data.id}`, data).then(r => r.data), //
	createData: (data) => axios.post(`/tariffEdit/add`, data).then(r => r.data),
	updateData: (data) => axios.post(`/tariffEdit/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (data) => axios.post(`/tariffEdit/del/${data.id}`).then(r => r.data),  

	getAreaParams: (data) => axios.post(`/tariffEdit/areas`).then(r => r.data), 
}
  