import * as types from './types';

// filter  
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_PHOTOSINSP_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_PHOTOSINSP_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_PHOTOSINSP, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_PHOTOSINSP, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_PHOTOSINSP, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_PHOTOSINSP_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_PHOTOSINSP_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_PHOTOSINSP, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_PHOTOSINSP_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_PHOTOSINSP_ERROR, error: payload})
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_PHOTOSINSP, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_PHOTOSINSP_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_PHOTOSINSP_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_PHOTOSINSP, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_PHOTOSINSP_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_PHOTOSINSP_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_PHOTOSINSP, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_PHOTOSINSP, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_PHOTOSINSP}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_PHOTOSINSP}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_PHOTOSINSP})

// rules
	// get
export const requestGetRules = (payload) => ({type: types.REQUEST_GET_RULES_PHOTOSINSP, payload})
export const getRulesSuccess = (payload) => ({type: types.GET_RULES_PHOTOSINSP_SUCCESS, payload: payload}) 
	// change rules
export const requestChangeRules = (payload) => ({type: types.REQUEST_CHANGE_RULES_PHOTOSINSP, payload})
export const changeRulesSuccess = (payload) => ({type: types.CHANGE_RULES_PHOTOSINSP_SUCCESS, payload: payload}) 
export const changeRulesError = (payload) => ({type: types.CHANGE_RULES_PHOTOSINSP_ERROR, payload: payload}) 
export const clearChangeRulesFields = () => ({type: types.CLEAR_CHANGE_RULES_FIELDS_PHOTOSINSP})
	// get photos for one
export const requestGetDriverPhotos= (payload) => ({type: types.REQUEST_GET_DRIVERPHOTOS_PHOTOSINSP, payload})
export const getDriverPhotosSuccess = (payload) => ({type: types.GET_DRIVERPHOTOS_PHOTOSINSP_SUCCESS, payload})  
	// agreeDisagreeWidthPhotos
export const requestAgreeDisagreeWidthPhotos = (payload) => ({type: types.REQUEST_AGREE_DISAGREE_PHOTOS_PHOTOSINSP, payload})
export const agreeDisagreeWidthPhotosSuccess = (payload) => ({type: types.SUCCESS_AGREE_DISAGREE_PHOTOS_PHOTOSINSP, payload})  
export const agreeDisagreeWidthPhotosError = (payload) => ({type: types.ERROR_AGREE_DISAGREE_PHOTOS_PHOTOSINSP, payload})  
export const clearFiledsAgreeDisagreeWidthPhotos = (payload) => ({type: types.CLEAR_GREE_DISAGREE_PHOTOS_FIELDS_PHOTOSINSP, payload})

/*
// get driver info
export const requestGetDriverInfo = (payload) => ({type: types.REQUEST_GET_DRIVER_INFO, payload})
export const getDriverInfoSuccess = (payload) => ({type: types.SUCCESS_GET_DRIVER_INFO, payload})
*/
