export default {     
  params: [ 
    {'label': 'Название', 'value': 'name'},         
  ],  
  tariff: [ 
    {'label': 'Фиксированный', 'value': '0'},         
    {'label': 'Поминутный', 'value': '1'},         
    {'label': 'Километраж', 'value': '2'},         
    {'label': 'Комбинированный', 'value': '3'},         
  ],  
}