import { call, put, takeLatest } from 'redux-saga/effects';
// import API from '../../../../api/controlRoom/map'; 
import * as actions from './actions' 
 
export default [ 
	// takeLatest(actions.requestGetDataPage, requestGetData),     
]

// 1
// function* requestGetData(action) {
// 	try {   
// 		const data = yield call(API.getData, action.payload);    
// 		yield put(actions.getDataPageSuccess(data))
		
// 	} catch (error) {  
// 		console.error('(getDataPageError MAP)', error);
// 		yield put(actions.getDataPageError(error))
// 	}
// } 