import axios from 'axios'

export default { 
	getData: (filter) => axios.post(`/corp/list`, filter).then(r => r.data),
	createData: (data) => axios.post(`/corp/add`, data).then(r => r.data),
	updateData: (data) => axios.post(`/corp/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (data) => axios.post(`/corp/del/${data.id}`).then(r => r.data), 
	getAudit: (data) => axios.post(`/corp/audit/${data.id}`, data.payload).then(r => r.data), 

	getPassengers: (filter) => axios.post(`/person/list`, filter).then(r => r.data), 
	addPassengers: (data) => axios.post(`/person/add/${data.id}`, data.payload).then(r => r.data), 
	updatePassengers: (data) => axios.post(`/person/upd/${data.id}`, data.payload).then(r => r.data), 
	delPassengers: (data) => axios.post(`/person/del/${data.id}`).then(r => r.data), 
	getAuditPassengers: (data) => axios.post(`/person/audit/${data.id}`, data.payload).then(r => r.data), 
} 