import * as actions from "./actions";
import { createReducer } from "redux-act";

const initialState = {
  filter: {
    rightFilter: {
      sourceId: null,
      destinationId: null,
      dateParams: "",
      startDate: "",
      endDate: "",
      id: null
    },
    page: 1,
    countPage: 100
  },
  data: [],
  loading: false,
  companiesData: [],
  loadingCompanies: false,
  update: {
    // для работы при изменении
    loading: false,
    error: false,
    success: false
  }
};

export default createReducer(
  {
    //filters
    [actions.requestChangeRightFilter]: (state, action) => ({
      ...state,
      loading: true,
      filter: {
        ...state.filter,
        rightFilter: { ...state.rightFilter, ...action.redux }
      }
    }),

    [actions.useFilterSuccess]: (state, action) => {
      return { ...state, loading: false, data: action };
    },
    
    // [actions.clearRightFilterFields]: (state, action) => ({
    //   ...state,
    //   filter: {
    //     ...state.filter,
    //     rightFilter: {
    //       ...state.rightFilter,
    //       sourceId: null,
    //       destinationId: null,
    //       dateParams: "",
    //       startDate: "",
    //       endDate: "",
    //       id: ""
    //     }
    //   }
    // }),
    // Get
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),

    // --- Async update data ->>
    [actions.requestUpdateData]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: true }
    }),

    [actions.updateDataSuccess]: (state, action) => {
      let newData = state.data.map(el => {
        if (el.id === action.id) el = action;
        return el;
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
          error: false
        },
        data: newData
      };
    },

    [actions.updateDataError]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: false, error: action, success: false }
    }),

    [actions.clearUpdateFields]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: false, error: false, success: false }
    }),

    //pagination
    [actions.changePagination]: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action }
    }),

    [actions.showWindowUpdate]: (state, action) => ({
      ...state,
      windowUpdateOpened: action
    }),

    [actions.requestCompaniesData]: (state, action) => ({
      ...state,
      loadingCompanies: true
    }),

    [actions.companiesDataSuccess]: (state, action) => {
      return { ...state, loadingCompanies: false, companiesData: action };
    },

    [actions.companiesDataError]: (state, action) => ({
      ...state,
      loadingCompanies: false,
      companiesData: [],
      error: action
    }),

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
