import { all } from 'redux-saga/effects';

import dictionaries from './pages/dictionaries/Saga';
import controlRoom from './pages/controlRoom/Saga';
import admin from './pages/admin/Saga'; 
import reports from './pages/reports/Saga'; 
import finances from './pages/finances/Saga';
import auth from './auth/Saga'; 
import staticData from './static/Saga';
import core from './core/Saga';

const rootSaga = function* root() {
  yield all([
    ...dictionaries,
    ...controlRoom,
    ...staticData,
    ...auth,
    ...admin,
    ...core,
    ...reports,
    ...finances
  ])
}
export default rootSaga