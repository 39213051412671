import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../../api/admin/personsPage";
import { pushBrandsSuccess } from "../../../../store/static/dictionaries/actions";
import * as actions from "./actions";

export default [
  takeLatest(actions.requestGetDataPage, requestGetData),
  takeLatest(actions.requestCreateData, createData),
  takeLatest(actions.requestDeleteData, deleteData),
  takeLatest(actions.requestUpdateData, updateData),
  takeLatest(actions.requestChangeStatus, useFilter),
  takeLatest(actions.requestChangeParamSearch, useFilter),
  takeLatest(actions.requestChangeRightFilter, useFilter),
  takeLatest(actions.requestGetOnePerson, requestGetOnePerson)
];

// 1
function* requestGetData(action) {
  try {
    const data = yield call(API.getData, action.payload);
    yield put(actions.getDataPageSuccess(data));
  } catch (error) {
    console.error("(getDataPageError personsPage)", error);
    yield put(actions.getDataPageError(error));
  }
}

function* requestGetOnePerson(action) {
	try {   
		const data = yield call(API.getOnePerson, action.payload);    
		yield put(actions.getOnePersonSuccess(data))   
		
	} catch (error) {
    console.error("getOnePerson ERROR", error)   
		yield put(actions.getOnePersonError(error))
	}
}

//
function* useFilter(action) {
  try {
    const data = yield call(API.getData, action.payload);
    yield put(actions.useFilterSuccess(data));
  } catch (error) {
    yield put(actions.useFilterSuccess([]));
  }
}

//
function* createData(action) {
  try {
    const data = yield call(API.createData, action.payload);
    yield put(actions.createDataSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(actions.createDataError(error));
  }
}


//
function* deleteData(action) {
  try {
    const data = yield call(API.deleteData, action.payload);
    yield put(actions.deleteDataSuccess(data));
  } catch (error) {
    console.error("(deleteData for personsPage) ", error);
    yield put(actions.deleteDataError(error));
  }
}

//
function* updateData(action) {
  try {
    console.log('saga action',action)
    const data = yield call(API.updateData, action.payload);
    console.log('saga data',data)
    yield put(actions.updateDataSuccess(data));
  } catch (error) {
    console.error("(updateData for personsPage) ", error);
    yield put(actions.updateDataError(error));
  }
}
