import axios from 'axios'

export default { 
	getData: (filter) => axios.post(`/order/list`, filter).then(r => r.data),
	createData: (filter) => axios.post(`/order/add`, filter).then(r => r.data),
	updateData: (data) => axios.post(`/order/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (data) => axios.post(`/order/del/${data.id}`, data.payload).then(r => r.data), 
 
	getOneOrder: (data) => axios.post(`/order/get/${data.id}`, data.payload).then(r=>r.data),
	sendOrderToExchanger: (data) => axios.post(`/order/orderinexchanger/${data.id}`).then(r=>r.data),
	getCounterparty: (filter) => axios.post(`/corp/list`, filter).then(r => r.data),
	getPersons: (filter) => axios.post(`/corp/person/list`, filter).then(r => r.data),
	getPassenger: (filter) => axios.post(`/client/get`, filter).then(r => r.data),
	getCoordinate: (filter) => axios.post(`/map/getGeoDataWithPurposes`, filter).then(r => r.data), 
	getSum: (filter) => axios.post(`/order/price`, filter).then(r => r.data), 
	getAudit: (data) => axios.post(`/order/audit`, data).then(r => r.data), 
	// getMapTrack: (data) => axios.post(`/order/points/19063`).then(r => r.data), // 16985, 19063
	getMapTrack: (data) => axios.post("/order/points", data).then(r => r.data), 
	getDriverTrack: (data) => axios.post("/order/driverPosition", data).then(r => r.data), 
	getTaximeter: (data) => axios.post(`/order/taximeter/${data.id}`).then(r => r.data), 
	getCompanies: (data) => axios.post('/order/companies', data).then(r=>r.data),
	sendCompaniesData: (data) => axios.post(`/order/completeOrder/${data.id}`, data.payload).then(r=>r.data)
} 