import * as actions from "./actions";
import { createReducer } from "redux-act";
import { filterDataForId } from "../../../../core/utils/filters/MainFilters";
// import dict from "../../../../core/dict/controlRoom/blacklist";

const initialState = {
  filter: { 
    // paramSearch: dict.params[0].value,
    // valueSearch: "",
    rightFilter: {},
    page: 1,
    countPage: 100
  },
  data: [],
  loading: false,
  create: {
    // для работы при создании
    loading: false,
    error: false,
    success: false
  },
  update: {
    // для работы при изменении
    loading: false,
    error: false,
    success: false
  },
  delete: {
    // при удалении
    loading: false,
    error: false,
    success: false
  } 
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    // --- filter
    [actions.requestChangeStatus]: (state, action) => ({
      ...state,
      loading: true,
      filter: { ...state.filter, ...action.redux }
    }),

    [actions.requestChangeParamSearch]: (state, action) => ({
      ...state,
      loading: true,
      filter: {
        ...state.filter,
        paramSearch: action.redux.paramSearch,
        valueSearch: action.redux.valueSearch
      }
    }),

    [actions.requestChangeRightFilter]: (state, action) => ({
      ...state,
      loading: true,
      filter: { ...state.filter, rightFilter: action }
    }),

    [actions.useFilterSuccess]: (state, action) => ({
      ...state,
      loading: false,
      data: filterDataForId(action)
    }),

    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: filterDataForId(action) };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),

    // --- Async create ->>
    [actions.requestCreateData]: (state, action) => ({
      ...state,
      create: { ...state.create, loading: true }
    }),

    [actions.createDataSuccess]: (state, action) => {
      let newData = state.data.concat(action);
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: true,
          error: false
        },
        data: filterDataForId(newData)
      };
    },

    [actions.createDataError]: (state, action) => {
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action,
          success: false
        }
      };
    },

    [actions.clearCreateFields]: (state, action) => ({
      ...state,
      create: { ...state.create, loading: false, error: false, success: false }
    }),

    // --- Async update data ->>
    [actions.requestUpdateData]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: true }
    }),

    [actions.updateDataSuccess]: (state, action) => {
      const newData = state.data.map(el => {
        if (el.id === action.id) el = action;
        return el;
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
          error: false
        },
        data: newData
      };
    },

    [actions.updateDataError]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: false, error: action, success: false }
    }),

    [actions.clearUpdateFields]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: false, error: false, success: false }
    }),

    // --- Async delete data ->>
    [actions.requestDeleteData]: (state, action) => ({
      ...state,
      delete: { ...state.delete, loading: true }
    }),

    [actions.deleteDataSuccess]: (state, action) => {
      const newData = (newData = state.data.filter(
        el => String(el.id) !== String(action)
      ));
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          success: true,
          error: false
        },
        data: filterDataForId(newData)
      };
    },

    [actions.deleteDataError]: (state, action) => ({
      ...state,
      delete: { ...state.delete, loading: false, error: action, success: false }
    }),

    [actions.clearDeleteFields]: (state, action) => ({
      ...state,
      delete: { ...state.delete, loading: false, error: false, success: false }
    }),

    // показ окон
    [actions.showWindowAdd]: (state, action) => ({
      ...state,
      windowCreateOpened: action
    }),
    [actions.showWindowUpdate]: (state, action) => ({
      ...state,
      windowUpdateOpened: action
    }),
    //pagination
    [actions.changePagination]: (state, action) => ({
      ...state,
      filter: {...state.filter, ...action}
    }),

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
