import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/dictionaries/photoInspection';
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_PHOTOSINSP, requestGetData),    
	takeLatest(types.REQUEST_CREATE_PHOTOSINSP, createData),    
	takeLatest(types.REQUEST_UPDATE_PHOTOSINSP, updateData),    
	takeLatest(types.REQUEST_DELETE_PHOTOSINSP, deleteData),    
	takeLatest(types.REQUEST_GET_RULES_PHOTOSINSP, requestGetRules),    
	takeLatest(types.REQUEST_CHANGE_RULES_PHOTOSINSP, requestChangeRules),    
	takeLatest(types.REQUEST_GET_DRIVERPHOTOS_PHOTOSINSP, requestGetDriverPhotos),    
	takeLatest(types.REQUEST_AGREE_DISAGREE_PHOTOS_PHOTOSINSP, agreeDisagreeWidthPhotos),    

	takeLatest(types.REQUEST_CHANGE_PHOTOSINSP_STATUS, useFilter),    
	takeLatest(types.REQUEST_CHANGE_PHOTOSINSP_SEARCH, useFilter),    
]

// 1
function* requestGetData(action) {
	try {    
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError PHOTOSINSP)', error);
		yield put(actions.getDataPageError(error))
	}
}

 
// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}
// 
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);  
		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error('(createData PHOTOSINSP) ', error); 
		yield put(actions.createDataError([]))
	}
}

// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR PHOTOSINSP) ', error); 
		yield put(actions.updateDataError(error))
	}
}

// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR PHOTOSINSP) ', error); 
		yield put(actions.deleteDataError(error))
	}
}

// RULES 
function* requestGetRules(action) {
	try {    
		const data = yield call(API.getRules, action.payload);    
		yield put(actions.getRulesSuccess(data))
		
	} catch (error) {   
		yield put(actions.getRulesSuccess([]))
	}
}

function* requestGetDriverPhotos(action) {
	try {    
		const data = yield call(API.getDriverPhotos, action.payload);    
		yield put(actions.getDriverPhotosSuccess(data))
		
	} catch (error) {   
		yield put(actions.getDriverPhotosSuccess([]))
	}
}

function* requestChangeRules(action) {
	try {    
		const data = yield call(API.changeRules, action.payload);    
		yield put(actions.changeRulesSuccess(data))
		
	} catch (error) {   
		yield put(actions.changeRulesError(true))
	}
}

function* agreeDisagreeWidthPhotos(action) {
	try {    
		const data = yield call(API.agreeDisagreeWidthPhotos, action.payload);    
		yield put(actions.agreeDisagreeWidthPhotosSuccess(data))
		
	} catch (error) {   
		yield put(actions.agreeDisagreeWidthPhotosError(true))
	}
}
