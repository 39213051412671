import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/admin/settingsPage';   
import * as types from './types' 
import * as actions from './actions' 
 
export default [      
	takeLatest(types.REQUEST_UPDATE_SETTINGAPP, updateData),     
	takeLatest(types.REQUEST_GET_COMPANY_FOR_SETTINGAPP, requestGetOneCompany),    
]
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateCompany, action.payload);   
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {     
		yield put(actions.updateDataError(true))
	}
}
    
//  
function* requestGetOneCompany(action) {
	try {    
		const data = yield call(API.getOneCompany, action.payload);    
		yield put(actions.getOneCompanySuccess(data))   
		
	} catch (error) {   
		yield put(actions.getOneCompanySuccess({}))
	}
}