import axios from 'axios'

const getOneCompany = {
	orderPlacementPercentage: 15,  // Процент размещения заказа  
	maxPercentageConsent: 22, // Максимальный процент согласия    
	transitionTimeToUrgent: 26, // Время перехода предварительного заказа в срочный(мин)
	maxPriceOrdersFromExchanger: 300, // Макисмальная цена для заказов из обменника
	timeConfirmRushOrder: 35, // Парк.Время для подтверждения срочного заказа(сек)
	deadlineStartPreOrder: 60, // Парк.Время до дедлайна для начало предварительного заказа(мин)
	percentageAddedInOrderOurApp: 3, // Добавляемый таксопарком процент при получении заказа из обменника(наше ПО)
	minutesBeforeAllowCancelOrderInMPM: 10, // Минуты до времени заказа, пока разрешена отмена в приложении MPM
	minutesAfterAllowCancelOrderInMPM: 9999, // Минуты после времени заказа, после котрых разрешена отмена в приложении MPM
	percentageTaxiInside: 15, // Процент таксопарка при внутреннем распределении
	createOrders: true, // Создает заказы
	performOrders: true, // Выполняет заказы
	sendPreOrders: true, // Посылать предв.заказы
	showDriversOrderPercentage: false, // Показывать водителям процент заказа при внутреннем распределении
	showOrdersInMPM: true, // Показывать в приложении MPM заказы обменника
	manualTachometerActivation: true, // Ручное вкл/выкл такометра заблокировано
	showTaximeterPoint: true, // Всегда показывать точку назначения в таксометре
	showTaximeterCoast: false, // Всегда показывать стоимость поездки в таксометре
}

export default {  
	updateCompany: (data) => axios.post(`/settingApps/upd/${data.id}`, data.payload).then(r => r.data),  
	getOneCompany: (data) => axios.post(`/settingApps/get/${data.id}`).then(r => r.data), 
}
  