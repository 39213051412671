import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[unIdentifiedObjects] requestChangeStatus');
export const requestChangeParamSearch = createAction('[unIdentifiedObjects] requestChangeParamSearch');
export const requestChangeRightFilter = createAction('[unIdentifiedObjects] requestChangeRightFilter');
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[unIdentifiedObjects] useFilterSuccess');
// get for unIdentifiedObjects
export const requestGetDataObjects = createAction('[unIdentifiedObjects] requestGetDataObjects');
export const getDataObjectsSuccess = createAction('[unIdentifiedObjects] getDataObjectsSuccess');
export const getDataObjectsError = createAction('[unIdentifiedObjects] getDataObjectsError')
// get
export const requestGetDataPage = createAction('[unIdentifiedObjects] requestGetDataPage');
export const getDataPageSuccess = createAction('[unIdentifiedObjects] getDataPageSuccess');
export const getDataPageError = createAction('[unIdentifiedObjects] getDataPageError');
// delete
export const requestDeleteData = createAction('[unIdentifiedObjects] requestDeleteData');
export const deleteDataSuccess = createAction('[unIdentifiedObjects] deleteDataSuccess');
export const deleteDataError = createAction('[unIdentifiedObjects] deleteDataError');
// create
export const sendModalData = createAction('[unIdentifiedObjects] sendModalData');
export const sendModalSuccess = createAction('[unIdentifiedObjects] sendModalSuccess');
export const sendModalError = createAction('[unIdentifiedObjects] sendModalError');
export const requestCreateData = createAction('[unIdentifiedObjects] requestCreateData');
export const createDataSuccess = createAction('[unIdentifiedObjects] createDataSuccess');
export const createDataError = createAction('[unIdentifiedObjects] createDataError'); 
// update
export const requestUpdateData = createAction('[unIdentifiedObjects] requestUpdateData'); 
export const updateDataSuccess = createAction('[unIdentifiedObjects] updateDataSuccess'); 
export const updateDataError = createAction('[unIdentifiedObjects] updateDataError'); 
// показ окон
export const showWindowAdd = createAction('[unIdentifiedObjects] showWindowAdd'); 
export const showWindowUpdate = createAction('[unIdentifiedObjects] showWindowUpdate'); 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[unIdentifiedObjects] clearModalFields');
export const clearSearchBrandFields = createAction('[unIdentifiedObjects] clearModalFields');
export const clearCreateFields = createAction('[unIdentifiedObjects] clearCreateFields'); 
export const clearUpdateFields = createAction('[unIdentifiedObjects] clearUpdateFields'); 
export const clearDeleteFields = createAction('[unIdentifiedObjects] clearDeleteFields');  
export const clearTableFields = createAction('[unIdentifiedObjects] clearTableFields');

// pagination
export const changePagination = createAction('[unIdentifiedObjects] changePagination');

//поиск по бренду в неопознанных объектах

export const getSearchBrandInfo = createAction('[unIdentifiedObjects] getSearchBrandInfo');
export const getSearchBrandInfoSuccess = createAction('[unIdentifiedObjects] getSearchBrandInfoSuccess');
export const getSearchBrandInfoError = createAction('[unIdentifiedObjects] getSearchBrandInfoError');
export const sendObjectsTableData = createAction('[unIdentifiedObjects] sendObjectsTableData');
export const sendObjectsTableDataSuccess = createAction('[unIdentifiedObjects] sendObjectsTableDataSuccess');
export const sendObjectsTableDataError = createAction('[unIdentifiedObjects] sendObjectsTableDataError');


//  получаем всю информацию об 1 машине
export const requestGetOneCar = createAction('[unIdentifiedObjects] requestGetOneCar');  
export const getOneCarSuccess = createAction('[unIdentifiedObjects] getOneCarSuccess'); 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 