import axios from 'axios'

export default {  
	getData: (filter) => axios.post(`/creatingUser/list`, filter).then(r => r.data),
	createData: (data) => axios.post(`/creatingUser/add`, data).then(r => r.data),
	updateData: (data) => axios.post(`/creatingUser/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (data) => axios.post(`/creatingUser/del/${data.id}`).then(r => r.data), 

	getCompanies: (filter) => axios.post(`/creatingCompany/list`, filter).then(r => r.data),
	getRoles: (filter) => axios.post(`/creatingUser/roles`, filter).then(r => r.data),
}
  