import * as actions from "./actions";
import { createReducer } from "redux-act";
import { carsBrands } from "../../../static/dictionaries/reducer";

const initialState = {
  filter: {
    startDate: new Date(),
    endDate: new Date(),
    orderNumber: "",
    from: "",
    to: "",
    paymentType: ""
  },
  common: {
    password: "",
    confirmPassword: "",
    comment: "",
  },
  errors: { error: false, errorPassword: false, errorConfirmPassword: false },
  tableData: [],
  data: [],
  loading: false,
  modalData: [],
  modal: {
    loading: false,
    error: false,
    success: false,
    text: undefined
  },
  create: {
    // для работы при создании
    loading: false,
    error: false,
    success: false
  },
  update: {
    // для работы при изменении
    loading: false,
    error: false,
    success: false
  },
  delete: {
    // при удалении
    loading: false,
    error: false,
    success: false
  },
  oneOrder: {
    data: {},
    loading: false,
    error: ""
  }
  // audit: {
  // 	data: [],
  // 	loading: true
  // }, // журнал
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    // --- filter
    [actions.requestChangeStatus]: (state, action) => ({
      ...state,
      loading: true,
      filter: { ...state.filter, ...action.redux }
    }),

    [actions.requestChangeParamSearch]: (state, action) => ({
      ...state,
      loading: true,
      filter: {
        ...state.filter,
        ...action.redux
      }
    }),

    [actions.requestChangeRightFilter]: (state, action) => ({
      ...state,
      loading: true,
      filter: {
        ...state.filter,
        rightFilter: { ...state.rightFilter, ...action.redux }
      }
    }),

    [actions.useFilterSuccess]: (state, action) => {
      return { ...state, loading: false, data: action };
    },

    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, tableData: action };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      tableData: [],
      error: action
    }),

    [actions.changeAnyFilter]: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action }
    }),

    // --- Async create ->>

    [actions.sendModalData]: (state, action) => ({
      ...state,
      modal: { ...state.modal, loading: true }
    }),

    [actions.sendModalSuccess]: (state, action) => {
      carsBrands.concat(action);
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          error: false,
          success: true,
          text: action.text
        }
      };
    },

    [actions.sendModalError]: (state, action) => {
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          error: action,
          success: false
        }
      };
    },

    [actions.clearModalFields]: (state, action) => ({
      ...state,
      modal: { ...state.modal, loading: false, error: false, success: false, text: undefined }
    }),

    [actions.requestCreateData]: (state, action) => ({
      ...state,
      create: { ...state.create, loading: true }
    }),

    [actions.createDataSuccess]: (state, action) => {
      let newData = state.data.concat(action);
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: true,
          error: false
        },
        data: newData
      };
    },

    [actions.createDataError]: (state, action) => {
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action,
          success: false
        }
      };
    },

    [actions.clearCreateFields]: (state, action) => ({
      ...state,
      create: { ...state.create, loading: false, error: false, success: false }
    }),

    // --- Async update data ->>
    [actions.requestUpdateData]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: true }
    }),

    [actions.updateDataSuccess]: (state, action) => {
      let newData = state.data.map(el => {
        if (el.id === action.id) el = action;
        return el;
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
          error: false
        },
        data: newData
      };
    },

    [actions.updateDataError]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: false, error: action, success: false }
    }),

    [actions.clearUpdateFields]: (state, action) => ({
      ...state,
      update: { ...state.update, loading: false, error: false, success: false }
    }),

    // --- Async delete data ->>
    [actions.requestDeleteData]: (state, action) => ({
      ...state,
      delete: { ...state.delete, loading: true }
    }),

    [actions.deleteDataSuccess]: (state, action) => {
      const newData = (newData = state.data.filter(
        el => String(el.id) !== String(action)
      ));
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          success: true,
          error: false
        },
        data: newData
      };
    },

    [actions.deleteDataError]: (state, action) => ({
      ...state,
      delete: { ...state.delete, loading: false, error: action, success: false }
    }),

    [actions.clearDeleteFields]: (state, action) => ({
      ...state,
      delete: { ...state.delete, loading: false, error: false, success: false }
    }),

    // показ окон
    [actions.showWindowAdd]: (state, action) => ({
      ...state,
      windowCreateOpened: action
    }),
    [actions.showWindowUpdate]: (state, action) => ({
      ...state,
      windowUpdateOpened: action
    }),
    //pagination
    [actions.changePagination]: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action }
    }),

    // получаем сервисы для таба
    [actions.requestGetOneOrder]: (state, action) => ({
      ...state,
      oneOrder: { ...state.oneOrder, loading: true }
    }),

    [actions.getOneOrderSuccess]: (state, action) => ({
        ...state,
        oneOrder: { ...state.oneOrder, loading: false, data: action }
    }),
    [actions.getOneOrderError]: (state,action) => ({
      ...state,
      oneOrder: { ...state.oneOrder, loading: false, data: {}, error: action }
    }),
    [actions.changeLoading]: (state, loading)=>({...state, loading}),

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
