import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters'; 
import dict from '../../../../core/dict/controlRoom/corpClients'; 
 

const initialState = { 
	filter: {
		status: 'any',  
		paramSearch: dict.params[0].value,  
		valueSearch: '',  
		rightFilter: { 
		 
		}, 
	}, 
	data: [],  
	loading: false,  
	create: { // для работы при создании 
		loading: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loading: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loading: false,  
		error: false,   
		success: false,   
	},   
	audit: {
		data: [], 
		loading: true
	}, // журнал для компаний

	passengersParams: {
		valueSearch: '', 
		paramSearch: dict.passengersParams[0].value, 
		who: dict.who[0].value, 
	}, // параметры для фильтры у пасаажиров
	passengersFields: {
		data: [],
		loading:false,
		modalCreateOpened: false,
		modalUpdateOpened: false,
		delete: {  
			loading: false,  
			error: false,   
			success: false,   
		},  
		create: {  
			loading: false,  
			error: false,   
			success: false,   
		},  
		update: {  
			loading: false,  
			error: false,   
			success: false,   
		},  
		audit: {  
			loading: false,    
			data: [],    
		},  
	} // поля для работы пассажирами
}

export default (state=initialState, action) => {
	  
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_CORPCLIENTS_STATUS:   
			return {...state, loading: true, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_CORPCLIENTS_SEARCH: 
			return {...state, loading: true, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}}

		case types.CHANGE_RIGHT_FILTER_CORPCLIENTS:  
			return {...state, loading: true, filter: {...state.filter, rightFilter: action.payload}}

		case types.USE_FILTER_SUCCESS_CORPCLIENTS:    
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.CHANGE_ANY_FILTER_CORPCLIENTS:   // non async  
			return {...state,  filter: {...state.filter, ...action.payload}} 
 

		// --- Async get ->>
		case types.REQUEST_GET_CORPCLIENTS: 
			return {...state, loading: true}

		case types.GET_DATA_CORPCLIENTS_SUCCESS:   
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.GET_DATA_CORPCLIENTS_ERROR: 
			return {...state, loading: false, data: [], error: action.payload} 


		// --- Async create ->>
		case types.REQUEST_CREATE_CORPCLIENTS: 
			return {...state, create: {...state.create, loading: true}}  
		case types.CREATE_CORPCLIENTS_SUCCESS: 
			let newData = state.data.concat(action.payload) 
			return {...state, create: {...state.create, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_CORPCLIENTS_ERROR: 
			return {...state, create: {...state.create, loading: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS_CORPCLIENTS: 
			return {...state, create: {...state.create, loading: false, error: false, success: false}} // initial  

		// --- Async update data ->>
	case types.REQUEST_UPDATE_CORPCLIENTS: 
		return {...state, update: {...state.update, loading: true}}  
	case types.UPDATE_CORPCLIENTS_SUCCESS: 
			newData = state.data.map(el => { 
			if(el.id === action.payload.id) el = action.payload
			return el
		})
		return {...state, update: {...state.update, loading: false, success: true, error: false}, data: newData}  
	case types.UPDATE_CORPCLIENTS_ERROR: 
		return {...state, update: {...state.update, loading: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_CORPCLIENTS: 
		return {...state, update: {...state.update, loading: false, error: false, success: false}} // initial 
 
 

		// --- Async delete data  Отмена заказа->>
		case types.REQUEST_DELETE_CORPCLIENTS:  
			return {...state, delete: {...state.delete, loading: true}}  
		case types.DELETE_CORPCLIENTS_SUCCESS:  
			// если статус отменные (6) = как и наш фильтр статус то ничего не делаем - иначе удаляем  
			newData = newData = state.data.filter(el=> el.id !== action.payload.id)  
			return {...state, delete: {...state.delete, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.DELETE_CORPCLIENTS_ERROR: 
			return {...state, delete: {...state.delete, loading: false, error: action.error, success: false}}  
		case types.CLEAR_DELETE_FIELDS_CORPCLIENTS: 
			return {...state, delete: {...state.delete, loading: false, error: false, success: false}} // initial 


		// показ окон
		case types.SHOW_WINDOW_ADD_CORPCLIENTS:   
			if(action.payload) 
				return {...state, windowCreateOpened: action.payload} 
			else
				return {...state, windowCreateOpened: action.payload, passengersParams: initialState.passengersParams, passengersFields: initialState.passengersFields} 
 
		case types.SHOW_WINDOW_UPDATE_CORPCLIENTS: 
			if(action.payload) 
				return {...state, windowUpdateOpened: action.payload} 
			else
				return {...state, windowUpdateOpened: action.payload, passengersParams: initialState.passengersParams, passengersFields: initialState.passengersFields}   
  
		 
		// --- WINDOWS request ->>   
		// показ окон для создание апдейта пассажиров 
		case types.SHOW_WINDOW_PASSENGERS_ADD_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, modalCreateOpened: action.payload}}  
		case types.SHOW_WINDOW_PASSENGERS_UPDATE_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, modalUpdateOpened: action.payload}}  

		// получаем журнал
		case types.REQUEST_GET_AUDIT_FOR_CORPCLIENTS:  
			return {...state, audit: {...state.audit, loading: true}}  
		case types.SUCCESS_GET_AUDIT_FOR_CORPCLIENTS:  
			return {...state, audit: {...state.audit, loading: false, data: action.payload}}  
 

		// --- Passengers 
		// get
		case types.REQUEST_GET_PASSENGERS_FOR_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, loading: true}}  
		case types.SUCCESS_GET_PASSENGERS_FOR_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, loading: false, data: filterDataForId(action.payload)}} 

		// del
		case types.REQUEST_DEL_PASSENGERS_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				delete: {...state.passengersFields.delete, loading: true}}}  

		case types.SUCCESS_DEL_PASSENGERS_CORPCLIENTS:  
			newData = state.passengersFields.data.filter(el => el !== action.payload)
			return {...state, passengersFields: {...state.passengersFields, 
				data: filterDataForId(newData),
				delete: {...state.passengersFields.delete, loading: false, success: true, error: false}}} 

		case types.ERROR_DEL_PASSENGERS_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				delete: {...state.passengersFields.delete, loading: false, success: false, error: action.payload}}} 

		case types.CLEAR_DELETE_PASSENGERS_FOR_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				delete: {...state.passengersFields.delete, loading: false, success: false, error: false}}} 

		// create
		case types.REQUEST_CREATE_PASSENGERS_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				create: {...state.passengersFields.delete, loading: true}}}  

		case types.SUCCESS_CREATE_PASSENGERS_CORPCLIENTS:  
			newData = [...state.passengersFields.data, action.payload] 
			return {...state, passengersFields: {...state.passengersFields, 
				data: filterDataForId(newData),
				create: {...state.passengersFields.delete, loading: false, success: true, error: false}}} 

		case types.ERROR_CREATE_PASSENGERS_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				create: {...state.passengersFields.delete, loading: false, success: false, error: action.payload}}} 

		case types.CLEAR_CREATE_PASSENGERS_FOR_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				create: {...state.passengersFields.delete, loading: false, success: false, error: false}}} 


		// update
		case types.REQUEST_UPDATE_PASSENGERS_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				update: {...state.passengersFields.delete, loading: true}}}  

		case types.SUCCESS_UPDATE_PASSENGERS_CORPCLIENTS:  
			newData = [...state.passengersFields.data] 
			newData = newData.map((el) => {
				if(el.id === action.payload.id) el = action.payload
				return el
			})  
			return {...state, passengersFields: {...state.passengersFields, 
				data: newData,
				update: {...state.passengersFields.delete, loading: false, success: true, error: false}}} 

		case types.ERROR_UPDATE_PASSENGERS_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				update: {...state.passengersFields.delete, loading: false, success: false, error: action.payload}}} 

		case types.CLEAR_UPDATE_PASSENGERS_FOR_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				update: {...state.passengersFields.delete, loading: false, success: false, error: false}}} 
	
		// получаем журнал для пассажира
		case types.REQUEST_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS:    
			return {...state, passengersFields: {...state.passengersFields, 
				audit: {...state.passengersFields.audit, loading: true}}} 
		case types.SUCCESS_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS:  
			return {...state, passengersFields: {...state.passengersFields, 
				audit: {...state.passengersFields.audit, loading: false, data: action.payload}}}  
			 
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  

		default: 
			return state
	}
}
 