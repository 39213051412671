import * as types from './types';

// меняем все названия типов. Пт редакс пргоняет через себя все
export const requestChangeStatus = (payload, filterData) => ({type: types.CHANGE_CAR_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.CHANGE_CAR_SEARCH, payload, filterData})  
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_CARS, payload, filterData})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_CAR, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_CAR, payload})  

// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.CARS_FILTER_SUCCESS, payload}) 
	 
// get  data
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_CARS, payload})
export const getDataPageSuccess = (payload) => ({type: types.REQUEST_GET_CARS_SUCCESS, payload})
export const getDataPageError = (payload) => ({type: types.REQUEST_GET_CARS_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_CAR, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_CAR_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_CAR_ERROR, error: payload})
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_CAR, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_CAR_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_CAR_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_CAR, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_CAR_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_CAR_ERROR, error: payload})
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS}) 
// get drivers
export const requestGetDriversForCars = (payload) => ({type: types.REQUEST_GET_DRIVERS_CARS, payload})
export const getDriversForCarsSuccess = (payload) => ({type: types.GET_DRIVERS_CARS_SUCCESS, payload: payload}) 
// get attachedDrivers
export const requestGetAttachedDriversForCar = (payload) => ({type: types.REQUEST_GET_ATTACHED_DRIVERS_CARS, payload})
export const getAttachedDriversForCarSuccess = (payload) => ({type: types.GET_ATTACHED_DRIVERS_CARS_SUCCESS, payload: payload}) 
// получаем данные 1 машину (вызов из Adapter.js)
export const requestGetOneCar = (payload) => ({type: types.REQUEST_GET_ONECAR_CARS, payload})
export const getOneCarSuccess = (payload) => ({type: types.SUCCESS_GET_ONECAR_CARS, payload})  
// меняем пагинацию 
export const changePagination = (payload) => ({type: types.CHANGE_PAGIN_CARS, payload}) 