import axios from 'axios'
import {catchError, downloadBlob} from '../apiTools';

export default {  
	getData: (data) => axios.post("/reports/reportAgent", data).then(r => r.data),
	getCompanies: (data) => axios.post("/reports/independentCompanies", data).then(r=>r.data),
	downloadFile: (data) => axios.post(`/reports/reportAgent/`, data, {responseType: 'blob'}).then(r => downloadBlob(r, `отчет агента ${data.companyId} ${new Date()}.xls`)),
	// createData: (data) => axios.post(`/classTaxi/add`, data).then(r => r.data).catch(r => catchError(r)),  
	// updateData: (data) => axios.post(`/classTaxi/upd/${data.id}`, data.payload).then(r => r.data),
  // deleteData: (data) => axios.post(`/classTaxi/del/${data.id}`).then(r => r.data)
}
	 