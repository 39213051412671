import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[clientsBlackList] requestChangeStatus')
export const requestChangeParamSearch = createAction('[clientsBlackList] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[clientsBlackList] requestChangeRightFilter')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[clientsBlackList] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[clientsBlackList] requestGetDataPage')
export const getDataPageSuccess = createAction('[clientsBlackList] getDataPageSuccess')
export const getDataPageError = createAction('[clientsBlackList] getDataPageError')
// delete
export const requestDeleteData = createAction('[clientsBlackList] requestDeleteData')
export const deleteDataSuccess = createAction('[clientsBlackList] deleteDataSuccess')
export const deleteDataError = createAction('[clientsBlackList] deleteDataError')
// create
export const requestCreateData = createAction('[clientsBlackList] requestCreateData')
export const createDataSuccess = createAction('[clientsBlackList] createDataSuccess')
export const createDataError = createAction('[clientsBlackList] createDataError') 
// update
export const requestUpdateData = createAction('[clientsBlackList] requestUpdateData') 
export const updateDataSuccess = createAction('[clientsBlackList] updateDataSuccess') 
export const updateDataError = createAction('[clientsBlackList] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[clientsBlackList] showWindowAdd') 
export const showWindowUpdate = createAction('[clientsBlackList] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = createAction('[clientsBlackList] clearCreateFields') 
export const clearUpdateFields = createAction('[clientsBlackList] clearUpdateFields') 
export const clearDeleteFields = createAction('[clientsBlackList] clearDeleteFields')  

// pagination
export const changePagination = createAction('[clientsBlackList] changePagination')

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 