import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_TARIFFEDIT_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_TARIFFEDIT_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_TARIFFEDIT, payload, filterData}) 
export const changeAnyFilter = (payload, filterData) => ({type: types.CHANGE_ANY_FILTER_TARIFFEDIT, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_TARIFFEDIT, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_TARIFFEDIT_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_TARIFFEDIT_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_TARIFFEDIT, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_TARIFFEDIT_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_TARIFFEDIT_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_TARIFFEDIT, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_TARIFFEDIT_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_TARIFFEDIT_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_TARIFFEDIT, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_TARIFFEDIT_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_TARIFFEDIT_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_TARIFFEDIT, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_TARIFFEDIT, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_TARIFFEDIT}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_TARIFFEDIT}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_TARIFFEDIT})   

//  получаем одного
export const requestGetOne = (payload) => ({type: types.REQUEST_GET_ONE_TARIFFEDIT, payload})    
export const successGetOne = (payload) => ({type: types.SUCCESS_GET_ONE_TARIFFEDIT, payload})  

// --- Windows request   
//  получаем области доставки
export const requestGetAreaParameters = (payload) => ({type: types.REQUEST_GET_AREAPARAMS_TARIFFEDIT, payload})  
export const getAreaParametersSuccess = (payload) => ({type: types.SUCCESS_GET_AREAPARAMS_TARIFFEDIT, payload})  