import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters';

const initialState = { 
	filter: {
		status: '0',  
		paramSearch: 'nickname', // поиск по колонке  
		valueSearch: '', // поиск по колонке - текстовое поле
		companyId: '',
		region: '',
		rightFilter: {   
			
		}, 
		page: 1,
		countPage: 100,
	}, 
	data: [],  // список машин
	loaded: false, // при загрузки машин
	create: { // для работы при создании 
		loaded: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loaded: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loaded: false,  
		error: false,   
		success: false,   
	},
	windowCreateOpened: false, 
	windowUpdateOpened: false,  
	cars: [], // список машин  
	oneCar: [], // 1 car 
	audit: {
		data: [], 
		loading: true
	}, // аудит
	auditParams: {
		data: [], 
		loading: true
	}, // аудит параметры
	driverOrders: {
		data: [], 
		loading: true
	}, // заказы
	events: {
		data: [], 
		loading: true
	}, // журнал событий
	finances: {
		data: [], 
		loading: true, 
		startDate: new Date(),
		endDate: new Date(),
	}, // финансы
	financesReplenish: {
		loading: false,  
		error: false,   
		success: false,  
	}, // созданик операции пополнить из раздела журналы
	financesWithdraw: {
		loading: false,  
		error: false,   
		success: false,  
	}, // созданик операции списать из раздела журналы
	driverPhotos: { // фото для одного водителя
		loading: false,  
		data: [],      
	},
	driverInfo: {}, // информация о водителе при нажатии на ссылку в OrdersPage
	currentDriverBalance: null, // баланс текущего выбранного водилы
	oneDriver: {}, // один водитель (вызов из Adapter.js)
}

export default (state=initialState, action) => { 
	  
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_DRIVER_STATUS:   
			return {...state, loaded: false, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_DRIVER_SEARCH: 
			return {...state, loaded: false, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}}

		case types.CHANGE_RIGHT_FILTER_DRIVERS:  
			return {...state, filter: {...state.filter, rightFilter: action.payload}}

		case types.CHANGE_PAGIN_DRIVERS:  
			return {...state, filter: {...state.filter, ...action.payload}}

		case types.USE_FILTER_SUCCESS_DRIVERS:   
			return {...state, loaded: true, data: filterDataForId(action.payload)} 
 

		// --- Async get ->>
		case types.REQUEST_GET_DRIVERS: 
			return {...state, loaded: false}

		case types.GET_DATA_DRIVERS_SUCCESS:   
			return {...state, loaded: true, data: filterDataForId(action.payload)} 

		case types.GET_DATA_DRIVERS_ERROR: 
			return {...state, loaded: true, data: [], error: action.payload} 


		// --- Async create ->>
		case types.REQUEST_CREATE_DRIVER: 
			return {...state, create: {...state.create, loaded: true}}  
		case types.CREATE_DRIVER_SUCCESS:  
			let newData = state.data.concat(action.payload)  
			return {...state, create: {...state.create, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_DRIVER_ERROR:    
			return {...state, create: {...state.create, loaded: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS_DRIVERS: 
			return {...state, create: {...state.create, loaded: false, error: false, success: false}} // initial  

		// --- Async update data ->>
	case types.REQUEST_UPDATE_DRIVERS: 
		return {...state, update: {...state.update, loaded: true}}  
	case types.UPDATE_DRIVERS_SUCCESS: 
			newData = state.data.map(el => { 
			if(el.id === action.payload.id) el = action.payload
			return el
		})
		return {...state, update: {...state.update, loaded: false, success: true, error: false}, data: newData}  
	case types.UPDATE_DRIVERS_ERROR: 
		return {...state, update: {...state.update, loaded: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_DRIVERS: 
		return {...state, update: {...state.update, loaded: false, error: false, success: false}} // initial 
 
 

		// --- Async delete data ->>
		case types.REQUEST_DELETE_DRIVERS: 
			return {...state, delete: {...state.delete, loaded: true}}  
		case types.DELETE_DRIVERS_SUCCESS:  
			newData = state.data.filter(el=> el.id !== action.payload) 
			return {...state, delete: {...state.delete, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.DELETE_DRIVERS_ERROR: 
			return {...state, delete: {...state.delete, loaded: false, error: action.error, success: false}}  
		case types.CLEAR_DELETE_FIELDS_DRIVERS: 
			return {...state, delete: {...state.delete, loaded: false, error: false, success: false}} // initial 


		// показ окон
		case types.SHOW_WINDOW_ADD_DRIVER:    
			return {...state, windowCreateOpened: action.payload, currentDriverBalance: initialState.currentDriverBalance} 
		case types.SHOW_WINDOW_UPDATE_DRIVER:    
			return {...state, windowUpdateOpened: action.payload, currentDriverBalance: initialState.currentDriverBalance} 

		// --- Async get cars in window ->>
		case types.REQUEST_GET_CARS_FOR_DRIVERS: 
			return {...state, cars: []}

		case types.GET_CARS_FOR_DRIVERS_SUCCESS:   
			return {...state, cars: action.payload} 
 

		// --- Async get cars in window ->>
		case types.REQUEST_GET_ONECAR_FOR_DRIVERS: 
			return {...state, oneCar: {}}

		case types.GET_ONECAR_FOR_DRIVERS_SUCCESS:  
			return {...state, oneCar: action.payload} 

		// --- Window requests
		// получаем аудит
		case types.REQUEST_GET_AUDIT_FOR_DRIVERS:  
			return {...state, audit: {...state.audit, loading: true}}  
		case types.SUCCESS_GET_AUDIT_FOR_DRIVERS:  
			return {...state, audit: {...state.audit, loading: false, data: filterDataForId(action.payload)}}  
		// получаем параметры аудита
		case types.REQUEST_GET_FILTERPARAMS_DRIVERS:  
			return {...state, auditParams: {...state.auditParams, loading: true}}  
		case types.GET_FILTERPARAMS_DRIVERS_SUCCESS:  
			return {...state, auditParams: {...state.auditParams, loading: false, data: filterDataForId(action.payload)}}  
		// получаем заказы водилы
		case types.REQUEST_GET_DRIVERORDERS_FOR_DRIVERS:  
			return {...state, driverOrders: {...state.driverOrders, loading: true}}  
		case types.SUCCESS_GET_DRIVERORDERS_FOR_DRIVERS:  
			return {...state, driverOrders: {...state.driverOrders, loading: false, data: filterDataForId(action.payload)}}  

		// получаем все события
		case types.REQUEST_GET_LOGEVENTS_FOR_DRIVERS:  
			return {...state, events: {...state.events, loading: true}}  
		case types.SUCCESS_GET_LOGEVENTS_FOR_DRIVERS:  
			return {...state, events: {...state.events, loading: false, data: filterDataForId(action.payload)}}  

		// получаем все финансы
		case types.REQUEST_GET_FINANCES_FOR_DRIVERS:  
			return {...state, finances: {...state.finances, loading: true, ...action.payload}}  
		case types.SUCCESS_GET_FINANCES_FOR_DRIVERS:  
			return {...state, finances: {...state.finances, loading: false, data: filterDataForId(action.payload)}}  

		// пополнение баланса в финансах
		case types.REQUEST_CREATE_REPLENISH_FOR_DRIVERS:  
			return {...state, financesReplenish: {...state.financesReplenish, loading: true}}  
		case types.SUCCESS_CREATE_REPLENISH_FOR_DRIVERS:  
			newData = state.data.map(el =>  {
				if(+el.id === +action.payload.id) el.balance = action.payload.balance
				return el
			}) 
			return {...state, financesReplenish: {...state.financesReplenish, loading: false, success: true, error: false},
			currentDriverBalance: action.payload.balance, data: filterDataForId(newData)} 
		case types.ERROR_CREATE_REPLENISH_FOR_DRIVERS:   
			return {...state, financesReplenish: {...state.financesReplenish, loading: false, success: false, error: action.error}}  
		case types.CLEARFIELD_CREATE_REPLENISH_FOR_DRIVERS:  
			return {...state, financesReplenish: initialState.financesReplenish}  

		// снятие денег в финансах
		case types.REQUEST_CREATE_WITHDRAW_FOR_DRIVERS:  
			return {...state, financesWithdraw: {...state.financesWithdraw, loading: true}}  
		case types.SUCCESS_CREATE_WITHDRAW_FOR_DRIVERS:  
			newData = state.data.map(el =>  {
				if(+el.id === +action.payload.id) el.balance = action.payload.balance
				return el
			})
			return {...state, financesWithdraw: {...state.financesWithdraw, loading: false, success: true, error: false},
			currentDriverBalance: action.payload.balance, data: filterDataForId(newData)}
		case types.ERROR_CREATE_WITHDRAW_FOR_DRIVERS:   
			return {...state, financesWithdraw: {...state.financesWithdraw, loading: false, success: false, error: action.error}}  
		case types.CLEARFIELD_CREATE_WITHDRAW_FOR_DRIVERS:  
			return {...state, financesWithdraw: initialState.financesWithdraw}
			
			
		// get photos for one
		case types.REQUEST_GET_DRIVERPHOTOS_DRIVERS: 
			return {...state, driverPhotos: {...state.driverPhotos, loading: true,}} 
		case types.GET_DRIVERPHOTOS_DRIVERS_SUCCESS:   
			return {...state, driverPhotos: {...state.driverPhotos, loading: false, data: action.payload}}
			
		// get photos for one
		case types.REQUEST_GET_OVEDRIVER_DRIVERS: 
			return {...state, oneDriver: {}} 
		case types.SUCCESS_GET_OVEDRIVER_DRIVERS:   
			return {...state, oneDriver: action.payload}

		// get driver info
		case types.REQUEST_GET_DRIVER_INFO:
			return {...state, driverInfo: {}}
		case types.SUCCESS_GET_DRIVER_INFO:
			return {...state, driverInfo: action.payload}

		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState 
		 
		default: 
			return state
	}
}
 