import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/dictionaries/cars';
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_CARS, requestGetCars), 
	takeLatest(types.REQUEST_CREATE_CAR, createData),   
	takeLatest(types.REQUEST_UPDATE_CAR, updateData),   
	takeLatest(types.REQUEST_DELETE_CAR, deleteData),   
	takeLatest(types.REQUEST_GET_DRIVERS_CARS, getDrivers),   
	takeLatest(types.REQUEST_GET_ATTACHED_DRIVERS_CARS, getAttachedDrivers),   
	
  takeLatest(types.CHANGE_CAR_STATUS, useFilter),   
  takeLatest(types.CHANGE_CAR_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_CARS, useFilter),     
	
  takeLatest(types.REQUEST_GET_ONECAR_CARS, requestGetOneCar),     
]

// 1
function* requestGetCars(action) {
	try {   
		const data = yield call(API.getCars, action.payload);  
		yield put(actions.getDataPageSuccess(data)) 
	} catch (error) {  
		console.error('(requestGetCars)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2 
function* useFilter(action) {
	try {   
		const data = yield call(API.getCars, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}

// 3 
function* createData(action) {
	try {    
		const data = yield call(API.createCar, action.payload);  
		yield put(actions.createDataSuccess(data))  
	} catch (error) {  
		console.error('(createData) ', error); 
		yield put(actions.createDataError(error))
	}
}

// 5
function* updateData(action) {
	try {   
		const data = yield call(API.updateCar, action.payload);  
		yield put(actions.updateDataSuccess(data)) 
		
	} catch (error) {  
		console.error('(updateData) ', error); 
		yield put(actions.updateDataError(error))
	}
}

// 6
function* getDrivers(action) {
	try {   
		const data = yield call(API.getDriversForCars, action.payload);  
		yield put(actions.getDriversForCarsSuccess(data))
		
	} catch (error) {  
		console.error('(getDrivers FOR cars) ', error); 
		yield put(actions.getDriversForCarsSuccess([]))
	}
}

// 7
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteCar, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR cars) ', error); 
		yield put(actions.deleteDataError(error))
	}
}
 
// 7
function* getAttachedDrivers(action) {
	try {   
		const data = yield call(API.getAttachedDriversForCars, action.payload);   
		yield put(actions.getAttachedDriversForCarSuccess(data))
		
	} catch (error) {  
		console.error('(getAttachedDrivers FOR cars) ', error); 
		yield put(actions.getAttachedDriversForCarSuccess([]))
	}
}
 
//  
function* requestGetOneCar(action) {
	try {   
		const data = yield call(API.getOneCar, action.payload);  
		yield put(actions.getOneCarSuccess(data))
		
	} catch (error) {   
		yield put(actions.getOneCarSuccess({}))
	}
}