import * as types from './types';

const initialState = {  
	carsBrands: [], 
	loadingBrands: true,

	carsModels: [], 
	loadingModels: true, 

	regions: [],  
	loadingRegions: true,  

	tariffs: [], 
	loadingTariffs: true,  

	dict_autoClassId: [], 
	loadingAutoClassId: true,  
 
	// !!! ПОКА НИГДЕ НЕ ИСПОЛЬЗУЕМ получение всех словарей (не оч оптимально, будем посомтреть)
	// all dict . Сюда добавим все словари из запроса. Иеархия словарей как и страниц: dictionaries->cars->autoClassId->[...]
	loadingAddDict: true,  
}

export const {carsBrands} = initialState

export default (state=initialState, action) => {
	switch (action.type) { 
		// --- Async get brands, models ->> 
		case types.PUSH_BRANDS_SUCCESS:
			return {...state, carsBrands: [...state.carsBrands, action.payload]}

		case types.REQUEST_GET_CARS_BRANDS:   
			return {...state, loadingBrands: true}  

		case types.REQUEST_GET_CARS_MODELS:   
			return {...state, loadingModels: true}   

		case types.GET_CARS_BRANDS_SUCCESS:   
			return {...state, carsBrands: action.payload, loadingBrands: false}   

		case types.GET_CARS_MODELS_SUCCESS: 
			return {...state, carsModels: action.payload, loadingModels: false}   

		// ---  get regions ->>     
		case types.SUCCESS_GET_REGIONS_DICT:  
			return {...state, regions: action.payload, loadingModels: false}   
 
		// tarrifs  
		case types.REQUEST_GET_TARIFFS: 
			return {...state, loadingTariffs: true}   
		case types.REQUEST_GET_TARIFFS_SUCCESS: 
			return {...state, tariffs: action.payload, loadingTariffs: false}   
 
		// autoClassId 
		case types.REQUEST_GET_AUTOCLASSID_STATIC: 
			return {...state, loadingAutoClassId: true}   
		case types.SUCCESS_GET_AUTOCLASSID_STATIC: 
			return {...state, loadingAutoClassId: false, dict_autoClassId: action.payload}   
 
		// all dict 
		case types.REQUEST_GET_ALLDICT_STATIC: 
			return {...state, loadingAddDict: true}   
		case types.SUCCESS_GET_ALLDICT_STATIC: 
			return {...state, loadingAddDict: false, ...action.payload}

		/*	// get driver info
		case types.REQUEST_GET_DRIVER_INFO:
			return {...state, driverInfo: {}}
		case types.SUCCESS_GET_DRIVER_INFO:
			return {...state, driverInfo: action.payload}*/

		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM': 
			return initialState  
			
		default: 
			return state
	}
}