import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[classTaxi] requestChangeStatus')
export const requestChangeParamSearch = createAction('[classTaxi] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[classTaxi] requestChangeRightFilter')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[classTaxi] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[classTaxi] requestGetDataPage')
export const getDataPageSuccess = createAction('[classTaxi] getDataPageSuccess')
export const getDataPageError = createAction('[classTaxi] getDataPageError')
// delete
export const requestDeleteData = createAction('[classTaxi] requestDeleteData')
export const deleteDataSuccess = createAction('[classTaxi] deleteDataSuccess')
export const deleteDataError = createAction('[classTaxi] deleteDataError')
// create
export const sendModalData = createAction('[classTaxi] sendModalData')
export const sendModalSuccess = createAction('[classTaxi] sendModalSuccess')
export const sendModalError = createAction('[classTaxi] sendModalError')
export const requestCreateData = createAction('[classTaxi] requestCreateData')
export const createDataSuccess = createAction('[classTaxi] createDataSuccess')
export const createDataError = createAction('[classTaxi] createDataError') 
// update
export const requestUpdateData = createAction('[classTaxi] requestUpdateData') 
export const updateDataSuccess = createAction('[classTaxi] updateDataSuccess') 
export const updateDataError = createAction('[classTaxi] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[classTaxi] showWindowAdd') 
export const showWindowUpdate = createAction('[classTaxi] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[classTaxi] clearModalFields')
export const clearCreateFields = createAction('[classTaxi] clearCreateFields') 
export const clearUpdateFields = createAction('[classTaxi] clearUpdateFields') 
export const clearDeleteFields = createAction('[classTaxi] clearDeleteFields') 
export const clearRightFilterFields = createAction('[classTaxi] clearRightFilterFields')

// pagination
export const changePagination = createAction('[classTaxi] changePagination')

// for window create in classtaxi
export const addAlias = createAction('[classTaxi] addAlias')
export const removeAlias = createAction('[classTaxi] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneCar = createAction('[classTaxi] requestGetOneCar')  
export const getOneCarSuccess = createAction('[classTaxi] getOneCarSuccess') 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 