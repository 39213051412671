import { createReducer } from 'redux-act';
import * as actions from './actions';
 
import dict from '../../../../core/dict/controlRoom/map';

const initialState = { 
	filter: {
		rightFilter: {
			autoClassId: '',
			status: dict.status[0].value,
			nickname: '',
			fio: '',
			phone: '',
			groupId: '',
			region: '',
			companyId: '',
			from: '2', 
		},
	}, 
	loading: false, // при загрузки фильтров 

	drivers: [],   
	orders: [], 
	info: {
		busyDrivers: 0,
		freeDrivers: 0,
		onOrderDrivers: 0,
	}, 
	 
}

export default createReducer({
	// get 
  [actions.getDataPageSuccess]: (state, payload) => ({...state,  ...payload}), 
	// filter
  [actions.requestChangeFilter]: (state, payload) => ({...state , loading: true, filter: payload.payload}),
  [actions.useFilterSuccess]: (state, payload) => ({...state , loading: false, ...payload}),
  [actions.useFilterError]: (state, payload) => ({...state , loading: false}),
  'LOGOUT_FROM_SYSTEM': (state, payload) => initialState,
}, initialState);  
 