// filter
export const REQUEST_CHANGE_DRIVERBLCKLST_STATUS = 'REQUEST_CHANGE_DRIVERBLCKLST_STATUS'
export const REQUEST_CHANGE_DRIVERBLCKLST_SEARCH = 'REQUEST_CHANGE_DRIVERBLCKLST_SEARCH'
export const CHANGE_RIGHT_FILTER_DRIVERBLCKLST = 'CHANGE_RIGHT_FILTER_DRIVERBLCKLST' 
export const USE_FILTER_SUCCESS_CLIENTS = 'USE_FILTER_SUCCESS_CLIENTS' 
export const CHANGE_ANY_FILTER_DRIVERBLCKLST = 'CHANGE_ANY_FILTER_DRIVERBLCKLST' // изменяем любой другой доп фильтр (не сайд-эффект)
// get
export const REQUEST_GET_DRIVERBLCKLST = 'REQUEST_GET_DRIVERBLCKLST'
export const GET_DATA_DRIVERBLCKLST_SUCCESS = 'GET_DATA_DRIVERBLCKLST_SUCCESS' 
export const GET_DATA_DRIVERBLCKLST_ERROR = 'GET_DATA_DRIVERBLCKLST_ERROR' 
// delete 
export const REQUEST_DELETE_DRIVERBLCKLST = 'REQUEST_DELETE_DRIVERBLCKLST'
export const DELETE_DRIVERBLCKLST_SUCCESS = 'DELETE_DRIVERBLCKLST_SUCCESS' 
export const DELETE_DRIVERBLCKLST_ERROR = 'DELETE_DRIVERBLCKLST_ERROR' 
// update 
export const REQUEST_UPDATE_DRIVERBLCKLST = 'REQUEST_UPDATE_DRIVERBLCKLST'
export const UPDATE_DRIVERBLCKLST_SUCCESS = 'UPDATE_DRIVERBLCKLST_SUCCESS' 
export const UPDATE_DRIVERBLCKLST_ERROR = 'UPDATE_DRIVERBLCKLST_ERROR' 
// create 
export const REQUEST_CREATE_DRIVERBLCKLST = 'REQUEST_CREATE_DRIVERBLCKLST'
export const CREATE_DRIVERBLCKLST_SUCCESS = 'CREATE_DRIVERBLCKLST_SUCCESS' 
export const CREATE_DRIVERBLCKLST_ERROR = 'CREATE_DRIVERBLCKLST_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_DRIVERBLCKLST = 'SHOW_WINDOW_ADD_DRIVERBLCKLST' 
export const SHOW_WINDOW_UPDATE_DRIVERBLCKLST = 'SHOW_WINDOW_UPDATE_DRIVERBLCKLST'  
// get cars in window
export const REQUEST_GET_CARS_FOR_DRIVERBLCKLST = 'REQUEST_GET_CARS_FOR_DRIVERBLCKLST' 
export const GET_CARS_FOR_DRIVERBLCKLST_SUCCESS = 'GET_CARS_FOR_DRIVERBLCKLST_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_DRIVERBLCKLST = 'REQUEST_GET_ONECAR_FOR_DRIVERBLCKLST' 
export const GET_ONECAR_FOR_DRIVERBLCKLST_SUCCESS = 'GET_ONECAR_FOR_DRIVERBLCKLST_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_DRIVERBLCKLST = 'CLEAR_CREATE_FIELDS_DRIVERBLCKLST' 
export const CLEAR_UPDATE_FIELDS_DRIVERBLCKLST = 'CLEAR_UPDATE_FIELDS_DRIVERBLCKLST'  
export const CLEAR_DELETE_FIELDS_DRIVERBLCKLST = 'CLEAR_DELETE_FIELDS_DRIVERBLCKLST'    


// --- Windows request 
// показ окон для создание апдейта пассажиров
export const SHOW_WINDOW_PASSENGERS_ADD_DRIVERBLCKLST = 'SHOW_WINDOW_PASSENGERS_ADD_DRIVERBLCKLST'  
export const SHOW_WINDOW_PASSENGERS_UPDATE_DRIVERBLCKLST = 'SHOW_WINDOW_PASSENGERS_UPDATE_DRIVERBLCKLST'  
//  поулчаем аудит
export const REQUEST_GET_AUDIT_FOR_DRIVERBLCKLST = 'REQUEST_GET_AUDIT_FOR_DRIVERBLCKLST'  
export const SUCCESS_GET_AUDIT_FOR_DRIVERBLCKLST = 'SUCCESS_GET_AUDIT_FOR_DRIVERBLCKLST'  

 