import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[dashboard] requestChangeStatus')
export const requestChangeParamSearch = createAction('[dashboard] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[dashboard] requestChangeRightFilter')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[dashboard] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[dashboard] requestGetDataPage')
export const getDataPageSuccess = createAction('[dashboard] getDataPageSuccess')
export const getDataPageError = createAction('[dashboard] getDataPageError')
// delete
export const requestDeleteData = createAction('[dashboard] requestDeleteData')
export const deleteDataSuccess = createAction('[dashboard] deleteDataSuccess')
export const deleteDataError = createAction('[dashboard] deleteDataError')
// create
export const sendModalData = createAction('[dashboard] sendModalData')
export const sendModalSuccess = createAction('[dashboard] sendModalSuccess')
export const sendModalError = createAction('[dashboard] sendModalError')
export const requestCreateData = createAction('[dashboard] requestCreateData')
export const createDataSuccess = createAction('[dashboard] createDataSuccess')
export const createDataError = createAction('[dashboard] createDataError') 
// update
export const requestUpdateData = createAction('[dashboard] requestUpdateData') 
export const updateDataSuccess = createAction('[dashboard] updateDataSuccess') 
export const updateDataError = createAction('[dashboard] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[dashboard] showWindowAdd') 
export const showWindowUpdate = createAction('[dashboard] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[dashboard] clearModalFields')
export const clearCreateFields = createAction('[dashboard] clearCreateFields') 
export const clearUpdateFields = createAction('[dashboard] clearUpdateFields') 
export const clearDeleteFields = createAction('[dashboard] clearDeleteFields') 
export const clearRightFilterFields = createAction('[dashboard] clearRightFilterFields')

// pagination
export const changePagination = createAction('[dashboard] changePagination')

// for window create in dashboard
export const addAlias = createAction('[dashboard] addAlias')
export const removeAlias = createAction('[dashboard] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneCar = createAction('[dashboard] requestGetOneCar')  
export const getOneCarSuccess = createAction('[dashboard] getOneCarSuccess') 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 