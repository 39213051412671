import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[regions] requestChangeStatus')
export const requestChangeParamSearch = createAction('[regions] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[regions] requestChangeRightFilter')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[regions] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[regions] requestGetDataPage')
export const getDataPageSuccess = createAction('[regions] getDataPageSuccess')
export const getDataPageError = createAction('[regions] getDataPageError')
// delete
export const requestDeleteData = createAction('[regions] requestDeleteData')
export const deleteDataSuccess = createAction('[regions] deleteDataSuccess')
export const deleteDataError = createAction('[regions] deleteDataError')
// create
export const sendModalData = createAction('[regions] sendModalData')
export const sendModalSuccess = createAction('[regions] sendModalSuccess')
export const sendModalError = createAction('[regions] sendModalError')
export const requestCreateData = createAction('[regions] requestCreateData')
export const createDataSuccess = createAction('[regions] createDataSuccess')
export const createDataError = createAction('[regions] createDataError') 
// update
export const requestUpdateData = createAction('[regions] requestUpdateData') 
export const updateDataSuccess = createAction('[regions] updateDataSuccess') 
export const updateDataError = createAction('[regions] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[regions] showWindowAdd') 
export const showWindowUpdate = createAction('[regions] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[regions] clearModalFields')
export const clearCreateFields = createAction('[regions] clearCreateFields') 
export const clearUpdateFields = createAction('[regions] clearUpdateFields') 
export const clearDeleteFields = createAction('[regions] clearDeleteFields') 
export const clearRightFilterFields = createAction('[regions] clearRightFilterFields')

// pagination
export const changePagination = createAction('[regions] changePagination')

// for window create in regions
export const addAlias = createAction('[regions] addAlias')
export const removeAlias = createAction('[regions] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneRegion = createAction('[regions] requestGetOneCar')  
export const getOneRegionSuccess = createAction('[regions] getOneCarSuccess') 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 