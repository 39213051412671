/**
 * Обрабатывает ошибки переданной функции (data) => axios.post(`/path`, data).then(r => catchError(r)), 
 * @param {Promise} res промис для обработки
 */
export const catchError = res => {     
	if (res.status === 200) {   
		return res.data
	} else { 
		console.error('catchError: !! :',res.response.data); 
		throw(res.response.data ? res.response.data : 'Error')
	} 
}
/** Создает окно для скачивания файлов */
export const downloadBlob = (r, fileName) => {
	// 2. Create blob link to download 
	let url = window.URL.createObjectURL(new Blob([r.data]));
	let link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	// 3. Append to html page
	document.body.appendChild(link);
	// 4. Force download
	link.click();
	// 5. Clean up and remove the link
	link.parentNode.removeChild(link);
 }