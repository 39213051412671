import * as actions from './actions';
import { createReducer } from 'redux-act';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters'; 
import dict from '../../../../core/dict/admin/partners'; 
  
const initialState = { 
	filter: { 
		paramSearch: dict.params[0].value,  
		valueSearch: '',  
		rightFilter: { 
		 
		}, 
	}, 
	data: [],  
	loading: false,   
	update: { // для работы при изменении 
		loading: false,  
		error: false,   
		success: false,   
	}, 
}

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer({
	// --- filter 
	[actions.requestChangeStatus]: (state, action) => ({...state, loading: true, filter: {...state.filter, status: action}}), 
	
	[actions.requestChangeParamSearch]: (state, action) => ({...state, loading: true, filter: {...state.filter, ...action.redux}}), 
	
  [actions.requestChangeRightFilter]: (state, action) => ({...state, loading: true, filter: {...state.filter, rightFilter: action}}), 
	
	[actions.useFilterSuccess]: (state, action) => ({...state, loading: false, data: filterDataForId(action)}), 

	[actions.changePagination]: (state, action) => ({...state, filter: {...state.filter, ...action}}), 
	


	// --- Async get ->>
	[actions.requestGetDataPage]: (state, action) => ({...state, loading: true}),

	[actions.getDataPageSuccess]: (state, action) => { 
		return {...state, loading: false, data: filterDataForId(action)}},

	[actions.getDataPageError]: (state, action) => ({...state, loading: false, data: [], error: action}),
 

	// --- Async update data ->>
	[actions.requestUpdateData]: (state, action) => {
		const newData = state.data.map(el => { 
			if(el.id === action.id) el[action.name] = action[action.name]
			return el
		})
		return {...state, update: {...state.update, loading: true}, data: newData}},

	[actions.updateDataSuccess]: (state, action) => ({...state, update: {...state.update, loading: false, success: true, error: false}}),

	[actions.updateDataError]: (state, action) => {
		//в случае неудачи отмеченный чекбокс вернуть в исходное состояние
		return {...state, update: {...state.update, loading: false, error: action, success: false}}},

	[actions.clearUpdateFields]: (state, action) => ({...state, update: {...state.update, loading: false, error: false, success: false}}),
 
 
	  
  'LOGOUT_FROM_SYSTEM': (state, action) => initialState,
}, initialState);  

 