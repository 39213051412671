import * as types from './types';

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_DRIVER_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_DRIVER_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_DRIVERS, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик 
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_DRIVERS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_DRIVERS, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_DRIVERS_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_DRIVERS_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_DRIVERS, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_DRIVERS_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_DRIVERS_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_DRIVER, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_DRIVER_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_DRIVER_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_DRIVERS, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_DRIVERS_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_DRIVERS_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_DRIVER, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_DRIVER, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_DRIVERS}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_DRIVERS}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_DRIVERS})  
// получаем авто по аяксу в новом окне
export const requestGetCarsForDrivers = (payload) => ({type: types.REQUEST_GET_CARS_FOR_DRIVERS, payload}) 
export const getCarsForDriversSuccess = (payload) => ({type: types.GET_CARS_FOR_DRIVERS_SUCCESS, payload})  
// получаем 1 авто
export const requestGetOneCar = (payload) => ({type: types.REQUEST_GET_ONECAR_FOR_DRIVERS, payload}) 
export const getOneCarSuccess = (payload) => ({type: types.GET_ONECAR_FOR_DRIVERS_SUCCESS, payload})  


// --- Window requests
	// получаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_DRIVERS, payload}) 
export const successGetAudit = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_DRIVERS, payload}) 
// получить все параметры-фильтры для аудита
export const requestGetAuditParams = (payload) => ({type: types.REQUEST_GET_FILTERPARAMS_DRIVERS, payload}) 
export const successGetAuditParams = (payload) => ({type: types.GET_FILTERPARAMS_DRIVERS_SUCCESS, payload}) 
	// получаем заказы водилы
export const requestGetDriverOrders = (payload) => ({type: types.REQUEST_GET_DRIVERORDERS_FOR_DRIVERS, payload}) 
export const successGetDriverOrders  = (payload) => ({type: types.SUCCESS_GET_DRIVERORDERS_FOR_DRIVERS, payload})   
	// получаем все события
export const requestGetLogEvents = (payload) => ({type: types.REQUEST_GET_LOGEVENTS_FOR_DRIVERS, payload}) 
export const successGetLogEvents  = (payload) => ({type: types.SUCCESS_GET_LOGEVENTS_FOR_DRIVERS, payload})
	//-- раздел финансы
		// получаем все финансы
export const requestGetFinances = (payload, filterData) => ({type: types.REQUEST_GET_FINANCES_FOR_DRIVERS,  payload, filterData}) 
export const successGetFinances = (payload) => ({type: types.SUCCESS_GET_FINANCES_FOR_DRIVERS, payload})   
		// создаем в финансах пополнение водителю
export const requestCreateReplenish = (payload) => ({type: types.REQUEST_CREATE_REPLENISH_FOR_DRIVERS,  payload}) 
export const successCreateReplenish = (payload) => ({type: types.SUCCESS_CREATE_REPLENISH_FOR_DRIVERS, payload})   
export const errorCreateReplenish = (payload) => ({type: types.ERROR_CREATE_REPLENISH_FOR_DRIVERS, payload})   
export const clearFieldsCreateReplenish = (payload) => ({type: types.CLEARFIELD_CREATE_REPLENISH_FOR_DRIVERS, payload})   
		//  снятие денег в финансах
export const requestCreateWidhdrow= (payload) => ({type: types.REQUEST_CREATE_WITHDRAW_FOR_DRIVERS,  payload}) 
export const successCreateWidhdrow = (payload) => ({type: types.SUCCESS_CREATE_WITHDRAW_FOR_DRIVERS, payload})   
export const errorCreateWidhdrow = (payload) => ({type: types.ERROR_CREATE_WITHDRAW_FOR_DRIVERS, payload})   
export const clearFieldsCreateWidhdrow = (payload) => ({type: types.CLEARFIELD_CREATE_WITHDRAW_FOR_DRIVERS, payload})     
	// get photos for one driver
export const requestGetDriverPhotos= (payload) => ({type: types.REQUEST_GET_DRIVERPHOTOS_DRIVERS, payload})
export const getDriverPhotosSuccess = (payload) => ({type: types.GET_DRIVERPHOTOS_DRIVERS_SUCCESS, payload}) 
// получаем данные 1 водителя (вызов из Adapter.js)
export const requestGetOneDriver = (payload) => ({type: types.REQUEST_GET_OVEDRIVER_DRIVERS, payload})
export const getOneDriverSuccess = (payload) => ({type: types.SUCCESS_GET_OVEDRIVER_DRIVERS, payload})
// get driver info
export const requestGetDriverInfo = (payload) => ({type: types.REQUEST_GET_DRIVER_INFO, payload})
export const getDriverInfoSuccess = (payload) => ({type: types.SUCCESS_GET_DRIVER_INFO, payload})
// меняем пагинацию 
export const changePagination = (payload) => ({type: types.CHANGE_PAGIN_DRIVERS, payload})
