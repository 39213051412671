import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters'; 
import dict from '../../../../core/dict/admin/users'; 
 

const initialState = { 
	filter: {  
		paramSearch: dict.params[0].value,  
		valueSearch: '',  
		companyId: '',  
		rightFilter: { 
		 
		}, 
	}, 
	data: [],  
	loading: false,  
	create: { // для работы при создании 
		loading: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loading: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loading: false,  
		error: false,   
		success: false,   
	},   
	companies: {
		data: [], 
		loading: true
	},  
	roles: {
		data: [], 
		loading: true
	},  
}

export default (state=initialState, action) => {
	  
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_CREATEUSER_STATUS:   
			return {...state, loading: true, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_CREATEUSER_SEARCH:  
			return {...state, loading: true, filter: {...state.filter, ...action.payload}}

		case types.CHANGE_RIGHT_FILTER_CREATEUSER:  
			return {...state, loading: true, filter: {...state.filter, rightFilter: action.payload}}

		case types.USE_FILTER_SUCCESS_CREATEUSER:    
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.CHANGE_ANY_FILTER_CREATEUSER:   // non async  
			return {...state,  filter: {...state.filter, ...action.payload}} 
 

		// --- Async get ->>
		case types.REQUEST_GET_CREATEUSER: 
			return {...state, loading: true}

		case types.GET_DATA_CREATEUSER_SUCCESS:   
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.GET_DATA_CREATEUSER_ERROR: 
			return {...state, loading: false, data: [], error: action.payload} 

		// --- Async create ->>
		case types.REQUEST_CREATE_CREATEUSER: 
			return {...state, create: {...state.create, loading: true}}  
		case types.CREATE_CREATEUSER_SUCCESS: 
			let newData = state.data.concat(action.payload) 
			return {...state, create: {...state.create, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_CREATEUSER_ERROR: 
			return {...state, create: {...state.create, loading: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS_CREATEUSER: 
			return {...state, create: {...state.create, loading: false, error: false, success: false}} // initial  

		// --- Async update data ->>
	case types.REQUEST_UPDATE_CREATEUSER: 
		return {...state, update: {...state.update, loading: true}}  
	case types.UPDATE_CREATEUSER_SUCCESS: 
			newData = state.data.map(el => { 
			if(el.id === action.payload.id) el = action.payload
			return el
		})
		return {...state, update: {...state.update, loading: false, success: true, error: false}, data: newData}  
	case types.UPDATE_CREATEUSER_ERROR: 
		return {...state, update: {...state.update, loading: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_CREATEUSER: 
		return {...state, update: {...state.update, loading: false, error: false, success: false}} // initial 
 
 

		// --- Async delete data  Отмена заказа->>
		case types.REQUEST_DELETE_CREATEUSER:  
			return {...state, delete: {...state.delete, loading: true}}  
		case types.DELETE_CREATEUSER_SUCCESS:  
			// если статус отменные (6) = как и наш фильтр статус то ничего не делаем - иначе удаляем  
			newData = newData = state.data.filter(el=> el.id !== action.payload)  
			return {...state, delete: {...state.delete, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.DELETE_CREATEUSER_ERROR: 
			return {...state, delete: {...state.delete, loading: false, error: action.error, success: false}}  
		case types.CLEAR_DELETE_FIELDS_CREATEUSER: 
			return {...state, delete: {...state.delete, loading: false, error: false, success: false}} // initial 


		// показ окон
		case types.SHOW_WINDOW_ADD_CREATEUSER:    
			return {...state, windowCreateOpened: action.payload} 
		case types.SHOW_WINDOW_UPDATE_CREATEUSER:    
			return {...state, windowUpdateOpened: action.payload} 
  
		 
		// --- WINDOWS request ->>   
		// компании
		case types.REQUEST_GET_COMPANIES_FOR_CREATEUSER:  
			return {...state, companies: {...state.companies, loading: true}}   
		case types.SUCCESS_GET_COMPANIES_FOR_CREATEUSER:  
			return {...state, companies: {...state.companies, loading: false, data: action.payload}}   
		// роли
		case types.REQUEST_GET_ROLES_FOR_CREATEUSER:  
			return {...state, roles: {...state.roles, loading: true}}   
		case types.SUCCESS_GET_ROLES_FOR_CREATEUSER:  
			return {...state, roles: {...state.roles, loading: false, data: action.payload}}   
 
 
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
			 
		default: 
			return state
	}
}
 