export default {    
  status: [ 
    {'value': '0', 'label': 'Все компании' },
    {'value': '1', 'label': 'Своя компания'}, 
  ], 
  params: [ 
    {'label': 'Телефон', 'value': 'phone'}, 
    {'label': 'Имя', 'value': 'name'},   
    {'label': 'Фамилия', 'value': 'surname'},   
    {'label': 'Номер заказа', 'value': 'orderId'},         
  ],  
}