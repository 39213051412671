import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_CLIENTS_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_CLIENTS_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_CLIENTS, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_CLIENTS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_CLIENTS, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_CLIENTS_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_CLIENTS_ERROR, error: payload}) 
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_CLIENTS, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_CLIENTS, payload})  
//  поулчаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_CLIENTS, payload})  
export const getGetAuditSuccess = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_CLIENTS, payload}) 