import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters'; 
import dict from '../../../../core/dict/controlRoom/blacklist'; 
 

const initialState = { 
	update: { // для работы при изменении 
		loading: false,  
		error: false,   
		success: false,   
	},
	oneCompany: {
		data: {},
		loading: false,
	} 
}
let newData = ''

export default (state=initialState, action) => {
	  
	switch (action.type) {
	 
		// --- Async update data ->>
	case types.REQUEST_UPDATE_SETTINGAPP: 
		return {...state, update: {...state.update, loading: true}}  
	case types.UPDATE_SETTINGAPP_SUCCESS:  
		return {...state, update: {...state.update, loading: false, success: true, error: false}, oneCompany: {...state.oneCompany, 
			data: action.payload}}  
	case types.UPDATE_SETTINGAPP_ERROR: 
		return {...state, update: {...state.update, loading: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_SETTINGAPP: 
		return {...state, update: {...state.update, loading: false, error: false, success: false}} // initial 
 
  
		// получаем компанию для таба
		case types.REQUEST_GET_COMPANY_FOR_SETTINGAPP:  
			return {...state, oneCompany: {...state.oneCompany, loading: true}}  
		case types.SUCCESS_GET_COMPANY_FOR_SETTINGAPP:  
			if(Object.keys(action.payload).length) newData = action.payload
			else  newData = state.oneCompany
			return {...state, oneCompany: {...state.oneCompany, loading: false, data: newData}}  
 
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
			 
		default: 
			return state
	}
}
 