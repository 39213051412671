import carsPage from './carsPage/saga';
import driversPage from './driversPage/saga';
import columnsPage from './columnsPage/saga';
import photoInspection from './photoInspection/saga';
import classTaxi from './classTaxi/saga'
import unIdentifiedObjects from './unIdentifiedObjects/saga'
 
export default [ 
  ...carsPage,
  ...driversPage,
  ...columnsPage,
  ...photoInspection,
  ...classTaxi,
  ...unIdentifiedObjects
]
