import { createReducer } from 'redux-act';
import * as actions from './actions'; 

const initialState = {    
  sidebarActive: true,  // открыт ил сайдбар слева
  
  // настройки нового открывающегося окна с дефолтом
  newWindowSettings: {height:  window.screen.height < 900 ? 550 : 800, width: 1000, centerscreen: true}, 
} 

/** 
 * Обеспечиваем глобальный стейт внутри приложения. компонентами которым нужен
*/
export default createReducer({ 
  [actions.showSidebar]: (state, payload) => ({...state, sidebarActive: payload !== 'undefined' ? payload : !state.sidebarActive}), 
  // [actions.changeSetting]: (state, payload) => ({...state, payload}),

  'LOGOUT_FROM_SYSTEM': (state, payload) => initialState,
}, initialState);  
 