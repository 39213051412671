// filter
export const REQUEST_CHANGE_CORPCLIENTS_STATUS = 'REQUEST_CHANGE_CORPCLIENTS_STATUS'
export const REQUEST_CHANGE_CORPCLIENTS_SEARCH = 'REQUEST_CHANGE_CORPCLIENTS_SEARCH'
export const CHANGE_RIGHT_FILTER_CORPCLIENTS = 'CHANGE_RIGHT_FILTER_CORPCLIENTS' 
export const USE_FILTER_SUCCESS_CORPCLIENTS = 'USE_FILTER_SUCCESS_CORPCLIENTS' 
export const CHANGE_ANY_FILTER_CORPCLIENTS = 'CHANGE_ANY_FILTER_CORPCLIENTS' // изменяем любой другой доп фильтр (не сайд-эффект)
// get
export const REQUEST_GET_CORPCLIENTS = 'REQUEST_GET_CORPCLIENTS'
export const GET_DATA_CORPCLIENTS_SUCCESS = 'GET_DATA_CORPCLIENTS_SUCCESS' 
export const GET_DATA_CORPCLIENTS_ERROR = 'GET_DATA_CORPCLIENTS_ERROR' 
// delete 
export const REQUEST_DELETE_CORPCLIENTS = 'REQUEST_DELETE_CORPCLIENTS'
export const DELETE_CORPCLIENTS_SUCCESS = 'DELETE_CORPCLIENTS_SUCCESS' 
export const DELETE_CORPCLIENTS_ERROR = 'DELETE_CORPCLIENTS_ERROR' 
// update 
export const REQUEST_UPDATE_CORPCLIENTS = 'REQUEST_UPDATE_CORPCLIENTS'
export const UPDATE_CORPCLIENTS_SUCCESS = 'UPDATE_CORPCLIENTS_SUCCESS' 
export const UPDATE_CORPCLIENTS_ERROR = 'UPDATE_CORPCLIENTS_ERROR' 
// create 
export const REQUEST_CREATE_CORPCLIENTS = 'REQUEST_CREATE_CORPCLIENTS'
export const CREATE_CORPCLIENTS_SUCCESS = 'CREATE_CORPCLIENTS_SUCCESS' 
export const CREATE_CORPCLIENTS_ERROR = 'CREATE_CORPCLIENTS_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_CORPCLIENTS = 'SHOW_WINDOW_ADD_CORPCLIENTS' 
export const SHOW_WINDOW_UPDATE_CORPCLIENTS = 'SHOW_WINDOW_UPDATE_CORPCLIENTS'  
// get cars in window
export const REQUEST_GET_CARS_FOR_CORPCLIENTS = 'REQUEST_GET_CARS_FOR_CORPCLIENTS' 
export const GET_CARS_FOR_CORPCLIENTS_SUCCESS = 'GET_CARS_FOR_CORPCLIENTS_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_CORPCLIENTS = 'REQUEST_GET_ONECAR_FOR_CORPCLIENTS' 
export const GET_ONECAR_FOR_CORPCLIENTS_SUCCESS = 'GET_ONECAR_FOR_CORPCLIENTS_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_CORPCLIENTS = 'CLEAR_CREATE_FIELDS_CORPCLIENTS' 
export const CLEAR_UPDATE_FIELDS_CORPCLIENTS = 'CLEAR_UPDATE_FIELDS_CORPCLIENTS'  
export const CLEAR_DELETE_FIELDS_CORPCLIENTS = 'CLEAR_DELETE_FIELDS_CORPCLIENTS'    


// --- Windows request 
// показ окон для создание апдейта пассажиров
export const SHOW_WINDOW_PASSENGERS_ADD_CORPCLIENTS = 'SHOW_WINDOW_PASSENGERS_ADD_CORPCLIENTS'  
export const SHOW_WINDOW_PASSENGERS_UPDATE_CORPCLIENTS = 'SHOW_WINDOW_PASSENGERS_UPDATE_CORPCLIENTS'  
//  поулчаем аудит
export const REQUEST_GET_AUDIT_FOR_CORPCLIENTS = 'REQUEST_GET_AUDIT_FOR_CORPCLIENTS'  
export const SUCCESS_GET_AUDIT_FOR_CORPCLIENTS = 'SUCCESS_GET_AUDIT_FOR_CORPCLIENTS'   


// --- пассажиры 
// get  
export const REQUEST_GET_PASSENGERS_FOR_CORPCLIENTS = 'REQUEST_GET_PASSENGERS_FOR_CORPCLIENTS'  
export const SUCCESS_GET_PASSENGERS_FOR_CORPCLIENTS = 'SUCCESS_GET_PASSENGERS_FOR_CORPCLIENTS' 
// create
export const REQUEST_CREATE_PASSENGERS_CORPCLIENTS = 'REQUEST_CREATE_PASSENGERS_CORPCLIENTS'  
export const SUCCESS_CREATE_PASSENGERS_CORPCLIENTS = 'SUCCESS_CREATE_PASSENGERS_CORPCLIENTS'    
export const ERROR_CREATE_PASSENGERS_CORPCLIENTS = 'ERROR_CREATE_PASSENGERS_CORPCLIENTS'  
export const CLEAR_CREATE_PASSENGERS_FOR_CORPCLIENTS = 'CLEAR_CREATE_PASSENGERS_FOR_CORPCLIENTS' 
// UPDATE
export const REQUEST_UPDATE_PASSENGERS_CORPCLIENTS = 'REQUEST_UPDATE_PASSENGERS_CORPCLIENTS'  
export const SUCCESS_UPDATE_PASSENGERS_CORPCLIENTS = 'SUCCESS_UPDATE_PASSENGERS_CORPCLIENTS'    
export const ERROR_UPDATE_PASSENGERS_CORPCLIENTS = 'ERROR_UPDATE_PASSENGERS_CORPCLIENTS'  
export const CLEAR_UPDATE_PASSENGERS_FOR_CORPCLIENTS = 'CLEAR_UPDATE_PASSENGERS_FOR_CORPCLIENTS' 
// del
export const REQUEST_DEL_PASSENGERS_CORPCLIENTS = 'REQUEST_DEL_PASSENGERS_CORPCLIENTS'  
export const SUCCESS_DEL_PASSENGERS_CORPCLIENTS = 'SUCCESS_DEL_PASSENGERS_CORPCLIENTS'   
export const ERROR_DEL_PASSENGERS_CORPCLIENTS = 'ERROR_DEL_PASSENGERS_CORPCLIENTS'  
export const CLEAR_DELETE_PASSENGERS_FOR_CORPCLIENTS = 'CLEAR_DELETE_PASSENGERS_FOR_CORPCLIENTS' 
// audit
export const REQUEST_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS = 'REQUEST_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS'  
export const SUCCESS_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS = 'SUCCESS_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS'    