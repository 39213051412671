import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom'; 
// redux
import {connect} from 'react-redux';  
// tools
import JwtTools from '../core/utils/other/jwtTools';
import PermissionsTools from '../core/utils/other/PermissionsTools'; 


class Private extends Component {
  /** Проверка валидности токена в lstg */
  checkUserData() { 
    let token = localStorage.getItem('user');  
    if (token) {  
      JwtTools.checkInvalidOrRedirect(token)
    } else { 
      console.log("token or roles not exist. redirect to login");
      return "/login";
    }
  } 
  

  render() {  
    console.log(this.props.user);
    let pathToRedirect = this.checkUserData(); 
    const hasReadPage = PermissionsTools.pageHasRights(this.props, 'R') 
    console.log(this.props,hasReadPage);
    /** 
     * 1) Проверка на то что токен валидный
     **/ 
    if (pathToRedirect) {
      return <Redirect to={{pathname: pathToRedirect, state: {from: this.props.location}}}/>

    /** 2) Проверка на доступ текущей '/page' и пермишена и с таким же названием 'page' на чтение 'R' 
     * если есть токен, но нет права на конкретную страницу - выдаем страницу ошибки
     *  '/' - всегда доступна для чтения, если есть валидный токен (проверяется выше) */
    } else if(!hasReadPage && this.props.location.pathname !== '/'){   
      return <Redirect to={{pathname: '/403', state: {from: this.props.location}}}/>
    } else{  
      return <Route path={this.props.path} component={this.props.component}/> // если все ок
    } 
  }
}

export default connect(  
  state => ({ 
    user: state.auth.login.user,    
  }),
)(Private);
  
  