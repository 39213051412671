import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/admin/roles';  
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_ROLES, requestGetData),   
	takeLatest(types.REQUEST_CREATE_ROLES, createData),    
	takeLatest(types.REQUEST_DELETE_ROLES, deleteData),    
	takeLatest(types.REQUEST_UPDATE_ROLES, updateData),  
	takeLatest(types.REQUEST_GET_COMPANIES_FOR_ROLES, getCompanies),  
	takeLatest(types.REQUEST_GET_ROLES_FOR_ROLES, getRoles),  

  takeLatest(types.REQUEST_CHANGE_ROLES_STATUS, useFilter),   
  takeLatest(types.REQUEST_CHANGE_ROLES_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_ROLES, useFilter),   
]

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError ROLES)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		console.error('(useFilter ROLES) ', error); 
	}
}
  
 
// 3
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);  
		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error('(createData ROLES) ', error); 
		yield put(actions.createDataError(error))
	}
}
 
// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR ROLES) ', error); 
		yield put(actions.deleteDataError(error))
	}
}
 
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR ROLES) ', error); 
		yield put(actions.updateDataError(error))
	}
}
 
// 
function* getCompanies(action) {
	try {   
		const data = yield call(API.getCompanies, action.payload);  
		yield put(actions.successGetCompanies(data))   
		
	} catch (error) {  
		console.error('(successGetCompanies FOR ROLES) ', error); 
		yield put(actions.successGetCompanies([]))
	}
}
function* getRoles(action) {
	try {   
		const data = yield call(API.getRoles, action.payload);  
		yield put(actions.successGetRoles(data))   
		
	} catch (error) {  
		console.error('(successGetRoles FOR ROLES) ', error); 
		yield put(actions.successGetRoles([]))
	}
}
   
 