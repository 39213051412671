import axios from 'axios'
import {catchError} from '../apiTools';

export default {  
	getData: (data) => axios.post("/reports/reportGiveOrder", data).then(r => r.data),
	getCompanies: (data) => axios.post("/reports/independentCompanies", data).then(r=>r.data)
	// createData: (data) => axios.post(`/classTaxi/add`, data).then(r => r.data).catch(r => catchError(r)),  
	// updateData: (data) => axios.post(`/classTaxi/upd/${data.id}`, data.payload).then(r => r.data),
  // deleteData: (data) => axios.post(`/classTaxi/del/${data.id}`).then(r => r.data)
}
	 