import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/controlRoom/map'; 
import * as actions from './actions' 
 
export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),    
	takeLatest(actions.requestChangeFilter, useFilter),    
]

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError MAP)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {    
		const data = yield call(API.getData, action.payload.filterData);  
		yield put(actions.useFilterSuccess(data)) 
	} catch (error) {  
		yield put(actions.useFilterError()) 
		console.error('(useFilter ORDERS) ', error); 
	}
}
  