import { createAction } from 'redux-act';
import * as types from "../../controlRoom/ordersPage/types";

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[personsPage] requestChangeStatus')
export const requestChangeParamSearch = createAction('[personsPage] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[personsPage] requestChangeRightFilter')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[personsPage] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[personsPage] requestGetDataPage')
export const getDataPageSuccess = createAction('[personsPage] getDataPageSuccess')
export const getDataPageError = createAction('[personsPage] getDataPageError')
// delete
export const requestDeleteData = createAction('[personsPage] requestDeleteData')
export const deleteDataSuccess = createAction('[personsPage] deleteDataSuccess')
export const deleteDataError = createAction('[personsPage] deleteDataError')
// create
export const sendModalData = createAction('[personsPage] sendModalData')
export const sendModalSuccess = createAction('[personsPage] sendModalSuccess')
export const sendModalError = createAction('[personsPage] sendModalError')
export const requestCreateData = createAction('[personsPage] requestCreateData')
export const createDataSuccess = createAction('[personsPage] createDataSuccess')
export const createDataError = createAction('[personsPage] createDataError') 
// update
export const requestUpdateData = createAction('[personsPage] requestUpdateData') 
export const updateDataSuccess = createAction('[personsPage] updateDataSuccess') 
export const updateDataError = createAction('[personsPage] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[personsPage] showWindowAdd') 
export const showWindowUpdate = createAction('[personsPage] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[personsPage] clearModalFields')
export const clearCreateFields = createAction('[personsPage] clearCreateFields') 
export const clearUpdateFields = createAction('[personsPage] clearUpdateFields') 
export const clearDeleteFields = createAction('[personsPage] clearDeleteFields') 
export const clearRightFilterFields = createAction('[personsPage] clearRightFilterFields')

// pagination
export const changePagination = createAction('[personsPage] changePagination')

// for window create in personsPage
export const addAlias = createAction('[personsPage] addAlias')
export const removeAlias = createAction('[personsPage] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOnePerson = createAction('[personsPage] requestGetOnePerson')  
export const getOnePersonSuccess = createAction('[personsPage] getOnePersonSuccess') 
export const getOnePersonError = createAction('[personsPage] getOnePersonError')
export const clearOnePerson = createAction('[personsPage] clearOnePerson')


// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 