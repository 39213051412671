/** Create, Read, Update, Delete */
export const RIGHTS = {D: false, U: false, R: false, C: false}
 

export default class PermissionsTools{

	/** 
	 * Обходим права константы RIGHTS и если справа на лево в полученном числе [perms] есть символ - 
	 * То преобразуем в булево определенном полю в константе
	 * @param {Number} perms число в 10ой системе
	 * 
	 * @returns {Object} с заготовленными правами {C: true, R: true, U: true, D: false}
	 */
	static _getRight(perms){
		let result = {}
		let rights = Number(perms).toString(2) // '111' перевод в 2ую систему  
		this._setPer('A', result, rights, 5) 
		this._setPer('C', result, rights, 4) 
		this._setPer('R', result, rights, 3) 
		this._setPer('U', result, rights, 2) 
		this._setPer('D', result, rights, 1)

		return result
	}

	/** Пробуем установить букву разрешения для _getRight 
	 * @param {String} L 'C"
	 * @param {Object} result  {...} финальынй результат куда записать букву и значение
	 * @param {String} rights  '0111' число прав в 2м кода
	 * @param {Number} num какой символ в числе '0111' занимаем бука разрешения 'R' с конца 
	 * @change result:{C: true, ...}
	*/
	static _setPer(L, result, rights, num){
		try { 
			result[L] = rights.length >= num ? Boolean(Number(rights[rights.length - num])) : false 
		} catch (e) {
			result[L] = false
		} 
	}

	/** 
	 * Делает противположное _getRight
	 * @param {Object} rules {C: true, R: true, U: true, D: false} 
	 * @returns {Number} число в 10системе с правами '14' === '1110'
	 */
	static _getNumberFromRights(rules){
		let result = []   
		for (const [key, value] of Object.entries(rules)) {
			if(key === 'D') result[4] = Number(value)
			if(key === 'U') result[3] = Number(value)
			if(key === 'R') result[2] = Number(value)
			if(key === 'C') result[1] = Number(value) 
			if(key === 'A') result[0] = Number(value) 
		}  
		return this._toDEC(result.join(''))
	}

	/** Проходим объект страниц и записываем в каждое поле объект прав по типу консты RIGHTS {} */
	static eachPageSetRights(pages){  
		let result = {}
		for (const key in pages) { 
			result[key] = this._getRight(pages[key]) 
		}
		return result
	}


  /**
   * Берет все у props: редаксовский пермишенc и сравнивает с урлом. Потом смотрим есть ли в объекте {R: true...} требуемое право
   * @param {user: {per: ..., location: {pathname: "/clients", ...}}} props пропсы react компонента
   * @param {String} action  C, R, U... позволено ли данное действие
   * @param {String} path (Необязательный) /orders  если указан то берем этот путь
	 * 
   * @return {Boolean} может ли юз смотреть страницу
   */
  static pageHasRights = (props, action, path=null) => {  
		try {   
			let per;
			if(path) per = props.user.per[path.substring(1)] // передан путь сравнения path = объект пермишен с правами 
			else per = props.user.per[props.location.pathname.substring(1)] // объект пермишен с правами  
			if(per && per[action]) return true
			else return false 
		} catch (error) {
			return false
		}
	}
	
	/**
	 * @param {String|Number} dec 2ное число '10011'
	 * @returns {Number} преобразует в 10ное число '7'
	 */
	static _toDEC = dec => {
		String(dec)
		var out = 0, len = dec.length, bit = 1;
		while( len-- ) {
			out += dec[ len ] == "1" ? bit : 0;
			bit <<= 1;
		}
		return out;
	}
}