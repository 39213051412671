import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../../api/admin/formOrderPage";
import { pushBrandsSuccess } from "../../../../store/static/dictionaries/actions";
import * as actions from "./actions";

export default [
  takeLatest(actions.requestGetDataPage, requestGetData),
  takeLatest(actions.requestCreateData, createData),
  takeLatest(actions.requestDeleteData, deleteData),
  takeLatest(actions.requestUpdateData, updateData),
  takeLatest(actions.requestChangeStatus, useFilter),
  takeLatest(actions.requestChangeParamSearch, useFilter),
  takeLatest(actions.requestChangeRightFilter, useFilter),
  takeLatest(actions.sendModalData, createModalData),
  takeLatest(actions.requestGetOneOrder, requestGetOneOrder)
];

// 1
function* requestGetData(action) {
  try {

    yield put(actions.changeLoading(true));
    
    const data = yield call(API.getData, action.payload);

    if(data && data.length === 0){
      
      yield put(actions.sendModalSuccess({text: `По вашему запросу ничего не найдено!`}));

    }else
      yield put(actions.getDataPageSuccess(data));

  } catch (error) {

    yield put(actions.getDataPageError(error));
    yield put(actions.sendModalError(`Ошибка при загрузке данных! ${''}`))

  }finally{
    yield put(actions.changeLoading(false));
  }
}

function* requestGetOneOrder(action) {
	try {   
		const data = yield call(API.getSingleOrder, action.payload);    
		yield put(actions.getOneOrderSuccess(data))   
		
	} catch (error) {   
		yield put(actions.getOneOrderError(error))
	}
}

//
function* useFilter(action) {
  try {
    const data = yield call(API.getData, action.payload.filterData);
    yield put(actions.useFilterSuccess(data));
  } catch (error) {
    yield put(actions.useFilterSuccess([]));
  }
}

//
function* createData(action) {
  try {
    const data = yield call(API.createData, action.payload);
    console.log('saga data', data)
    yield put(actions.createDataSuccess(data));
    yield put(pushBrandsSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(actions.createDataError(error));
  }
}

//
function* createModalData(action) {
  try {
    const data = yield call(API.createModalData, action.payload);
    yield put(actions.sendModalSuccess(data));
    yield put(pushBrandsSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(actions.sendModalError(error.errorMessage));
  }
}

//
function* deleteData(action) {
  try {
    const data = yield call(API.deleteData, action.payload);
    yield put(actions.deleteDataSuccess(data));
  } catch (error) {
    console.error("(deleteData for classTaxi) ", error);
    yield put(actions.deleteDataError(error));
  }
}

//
function* updateData(action) {
  try {
    console.log('saga action',action)
    const data = yield call(API.updateData, action.payload);
    console.log('saga data',data)
    yield put(actions.updateDataSuccess(data));
  } catch (error) {
    console.error("(updateData for classTaxi) ", error);
    yield put(actions.updateDataError(error));
  }
}
