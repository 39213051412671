import reportManager from './reportManager/reducer';
import reportGiveOrder from "./reportGiveOrder/reducer";
import reportRecieveOrder from "./reportRecieveOrder/reducer";
import reportAgent from "./reportAgent/reducer";  

export default (state={}, action) => {  
	return {
		reportManager: reportManager(state.reportManager, action),
		reportGiveOrder: reportGiveOrder(state.reportGiveOrder, action),
		reportRecieveOrder: reportRecieveOrder(state.reportRecieveOrder, action),
		reportAgent: reportAgent(state.reportAgent, action)   
	} 
} 