import cars from './carsPage/reducer';
import drivers from './driversPage/reducer';
import columns  from './columnsPage/reducer';
import photoInspection  from './photoInspection/reducer';
import classTaxi from './classTaxi/reducer';
import unIdentifiedObjects from './unIdentifiedObjects/reducer'


export default (state={}, action) => {   
	return {
		cars: cars(state.cars, action), 
		drivers: drivers(state.drivers, action), 
		columns: columns(state.columns, action), 
		photoInspection: photoInspection(state.photoInspection, action),
		classTaxi: classTaxi(state.classTaxi, action),
		unIdentifiedObjects: unIdentifiedObjects(state.unIdentifiedObjects, action) 
	} 
} 