import jwtDecode from 'jwt-decode';
import history from '../../../history';
import PermissionsTools from './PermissionsTools';

/** инструменты для jwt */
class JwtTools {

	/** Проверка на валидность. если нет - то редирект на /login + удаляет токен с локалсторадж
	 * @returns {Boolean} если ок или /login redirect
	 */
	checkInvalidOrRedirect(token){
		try {
			jwtDecode(token)
			return true
		} catch (error) {   
			this._redirectForFailDecode()
		}
	}

	/** 
	 * Получаем информацию из токена и устонавливаем пермишины на всех страницах объекта для дальнешей отправки в редакс
	 */
	getDataToken(token){
		try { 
			const info = jwtDecode(token) 
			const pages = PermissionsTools.eachPageSetRights(info.per)  // преобразуем пермишины из 10нго числа в объект {C: true, ...} 
			return {...info, per: pages}
		} catch (error) {   
			this._redirectForFailDecode()
		}
	}

	/** Редиректим и неверный удаляем токен */
	_redirectForFailDecode(){
		localStorage.removeItem('user');
		history.push('/login') 
	}
}

export default new JwtTools()