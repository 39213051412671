import { call, takeLatest } from 'redux-saga/effects';
import API from '../../../api/auth/auth';  

 
export default [     
	takeLatest('LOGOUT_FROM_SYSTEM', logout),   
]
  
// 2
function* logout(action) {
	try {   
		yield call(API.logout, action.payload);   
	} catch (error) {  

	} 
} 